import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators, ReactiveFormsModule } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { GeneralserviceService } from '../../generalservice.service';
import Swal from 'sweetalert2';
import { HttpClient } from '@angular/common/http';
//import { StepsModule } from 'primeng/steps';
//import { ToastModule } from 'primeng/toast';
//import { MenuItem, MessageService } from 'primeng/api';

//import { ButtonModule } from 'primeng/button';
@Component({
  selector: 'app-sellform',

  templateUrl: './sellform.component.html',
  styleUrl: './sellform.component.scss'
})
export class SellformComponent {

    HomeUrl: string;
    arr: any[] = [];
    loginDet: any;
    otpSent: boolean = false;
    showLogin: boolean = false;
    showLogin1: boolean = true;
    registerAsDonor: boolean = false;
    RegId: any;
    LoginDetails: any;
    vehicleForm: FormGroup;
    sellForm: FormGroup;
    buyForm: FormGroup;
    RegisterForm: FormGroup;
    signForm: FormGroup;
    OTPtoMobile: string;
    checkNo: number;
    Mobilenum: string;
    mobiledata: any;
    classdata: any;
    classname: any;
    deptList: any;
    deptList1: any;
    // State: any;
    StateId: any;
    DistrictName: any;
    flag: any;
    rolename: any
    Districtdata: any;
    selectedDistrictName: any;
    Statedata: any;
    State: any = [];
    selectedStateName: any;
    selectedstateid: any;
    district: any = [];
    Citydata: any = [];
    Fdata: any;
    aggrementNo: any
    isUpdateMode: boolean = false;
// items: MenuItem[] | undefined;
    activeIndex: number = 0;

   
    active: number = 0;

    currentStep: number = 1;
    ImageData: any;
    image: any;
    EImage: any;
    Exteriorimg: any;
    ImageArray: any;
    maxImages: any;
    BuyerSignature: any;
    WitnessSignature: any;
    VehiclePhoto: any;
    constructor(
        public router: Router,
        private formBuilder: FormBuilder,
        public generalService: GeneralserviceService,
        public http: HttpClient,
        public activeroute: ActivatedRoute,
      //  public messageService: MessageService
    ) {
        this.sellForm = this.formBuilder.group({
            SellerName: ['', [Validators.required]],
            SellerFatherName: ['', [Validators.required]],
            SellerAge: ['', [Validators.required]],
            SellerOccupation: ['', [Validators.required]],
            SellerHNo: ['', [Validators.required]],
            SellerStreet: ['', [Validators.required]],
            SellerColony: ['', [Validators.required]],
            SellerMandal: ['', [Validators.required]],
            SellerState: ['', [Validators.required]],
            SellerPincode: ['', [Validators.required]],
            SellerMobile: ['', [Validators.required]],
        });
        this.buyForm = this.formBuilder.group({
            BuyerName: ['', [Validators.required]],
            BuyerFatherName: ['', [Validators.required]],
            BuyerAge: ['', [Validators.required]],
            BuyerOccupation: ['', [Validators.required]],
            BuyerHNo: ['', [Validators.required]],
            BuyerStreet: ['', [Validators.required]],
            BuyerColony: ['', [Validators.required]],
            BuyerMandal: ['', [Validators.required]],
            BuyerState: ['', [Validators.required]],
            BuyerPincode: ['', [Validators.required]],
            BuyerMobile: ['', [Validators.required]],
        });
        this.vehicleForm = this.formBuilder.group({
            VehicleRegistrationNo: ['', [Validators.required]],
            VehicleChassisNo: ['', [Validators.required]],
            VehicleEngineNo: ['', [Validators.required]],
            VehicleType: ['', [Validators.required]],
         ///   TransportType: ['', [Validators.required]],
            VehicleModel: ['', [Validators.required]],
            VehicleFuelType: ['', [Validators.required]],
            FitnessValidity: ['', [Validators.required]],
            PermitNumber: ['', [Validators.required]],
            PermitValidity: ['', [Validators.required]],
            MeterSealValidity: ['', [Validators.required]],
            CNGLPGValidity: ['', [Validators.required]],
            EVBatteryValidity: ['', [Validators.required]],
            TaxValidity: ['', [Validators.required]],
            RCValidity: ['', [Validators.required]],
            PaymentDueDate: ['', [Validators.required]],
            VehiclePrice: ['', [Validators.required]],
            PaidAmount: ['', [Validators.required]],
            RemainingAmount: ['', [Validators.required]],
            FinancierName: ['', [Validators.required]],
            FinancierAddress: ['', [Validators.required]],




            SellerSignature: ['', [Validators.required]],
            BuyerSignature: ['', [Validators.required]],
            WitnessSignature: ['', [Validators.required]],
            VehiclePhoto: ['', [Validators.required]],
        });

        this.aggrementNo = localStorage.getItem("AgreementNo");
    }

    ngOnInit() {
        //this.items = [
        //    {
        //        label: 'Personal',
        //        command: (event: any) => this.messageService.add({ severity: 'info', summary: 'First Step', detail: event.item.label })
        //    },
        //    {
        //        label: 'Address',
        //        command: (event: any) => this.messageService.add({ severity: 'info', summary: 'Second Step', detail: event.item.label })
        //    },
        //    {
        //        label: 'Summary',
        //        command: (event: any) => this.messageService.add({ severity: 'info', summary: 'Third Step', detail: event.item.label })
        //    }
        //];
    }

    onActiveIndexChange(event: number) {
        this.activeIndex = event;
    }
    goToNext() {
        if (this.currentStep === 1) {
            // Save seller details to localStorage
            const sellerData = this.sellForm.value;  // Assuming sellerForm is your form for seller details
            localStorage.setItem('seller', JSON.stringify(sellerData));
          //  console.log('Seller details saved:', sellerData);
        }

        if (this.currentStep === 2) {
            // Save buyer details to localStorage
            const buyerData = this.buyForm.value;  // Assuming buyerForm is your form for buyer details
            localStorage.setItem('buyer', JSON.stringify(buyerData));
          //  console.log('Buyer details saved:', buyerData);
        }
        if (this.currentStep === 3) {
            // Save buyer details to localStorage
            const vehicleData = this.vehicleForm.value;  // Assuming buyerForm is your form for buyer details
            localStorage.setItem('Vehicle', JSON.stringify(vehicleData));
            //  console.log('Buyer details saved:', buyerData);
        }
        if (this.currentStep < 4) {
            this.currentStep++;
        }
    }
    page() {
        this.router.navigate(['/Sell1form'])
    }

    goToPrevious() {
        if (this.currentStep > 1) {
            this.currentStep--;
        }
    }



    storeSellerData() {
        const sellerData = this.RegisterForm.value;
        localStorage.setItem('buyer', JSON.stringify(sellerData));
    }

    getRegistration(formValue: any) {

        // Store the data in localStorage
        this.storeSellerData();
    }

    updateRegistration(formValue: any) {

        this.storeSellerData();
    }
    removeImage1(index: number): void {
        this.Exteriorimg.splice(index, 1);
        this.ImageArray.splice(index, 1);
    }
    removeImage(index: number): void {
        this.BuyerSignature.splice(index, 1);
        this.ImageArray.splice(index, 1);
    }
    removeImage2(index: number): void {
        this.WitnessSignature.splice(index, 1);
        this.ImageArray.splice(index, 1);
    }
    removeImage3(index: number): void {
        this.VehiclePhoto.splice(index, 1);
        this.ImageArray.splice(index, 1);
    }

    detectFiles(event): void {
        let file = event.target.files[0]; // Only take the first file

        if (file) {
            let reader = new FileReader();
            reader.onload = (e: any) => {
                this.Exteriorimg = [e.target.result]; // Replace with the new image
                localStorage.setItem('signature_exterior', e.target.result); // Save to localStorage
            };
            reader.readAsDataURL(file);
            this.fileChange(event);
        } else {
            this.generalService.ShowAlert("warning", 'No file selected', 'warning');
        }
    }

    detectFiles1(event): void {
        let file = event.target.files[0]; // Only take the first file

        if (file) {
            let reader = new FileReader();
            reader.onload = (e: any) => {
                this.BuyerSignature = [e.target.result]; // Replace with the new image
                localStorage.setItem('signature_buyer', e.target.result); // Save to localStorage
            };
            reader.readAsDataURL(file);
            this.fileChange(event);
        } else {
            this.generalService.ShowAlert("warning", 'No file selected', 'warning');
        }
    }

    detectFiles2(event): void {
        let file = event.target.files[0]; // Only take the first file

        if (file) {
            let reader = new FileReader();
            reader.onload = (e: any) => {
                this.WitnessSignature = [e.target.result]; // Replace with the new image
                localStorage.setItem('signature_witness', e.target.result); // Save to localStorage
            };
            reader.readAsDataURL(file);
            this.fileChange(event);
        } else {
            this.generalService.ShowAlert("warning", 'No file selected', 'warning');
        }
    }

    detectFiles3(event): void {
        let file = event.target.files[0]; // Only take the first file

        if (file) {
            let reader = new FileReader();
            reader.onload = (e: any) => {
                this.VehiclePhoto = [e.target.result]; // Replace with the new image
                localStorage.setItem('signature_vehicle', e.target.result); // Save to localStorage
            };
            reader.readAsDataURL(file);
            this.fileChange(event);
        } else {
            this.generalService.ShowAlert("warning", 'No file selected', 'warning');
        }
    }


    //This is for Uploading Multiple Images
    fileChange(event) {
        debugger
        let fileList = event.target.files;

        if (fileList.length > 0) {
            const frmData = new FormData();
            for (var i = 0; i < fileList.length; i++) {
                frmData.append("PostedFile", fileList[i]);
            }
            var url = 'api/FinancierLaw/Post_Images';
            this.generalService.PostData(url, frmData).subscribe((data: any) => {
                if (data != null) {
                    this.ImageData = data;
                    this.image = data;
                    this.EImage.push({ ExteriorImages: this.ImageData })
                }
            });
        }

    }



    submitVehicleDetails(formValue: any) {
        console.log('Vehicle Form Submitted:', formValue);
        // Additional logic for vehicle form submission
    }

    updateVehicleDetails(formValue: any) {
        console.log('Vehicle Form Updated:', formValue);
        // Additional logic for vehicle form update
    }
    formatDate(dateString: string): string {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = ('0' + (date.getMonth() + 1)).slice(-2);
        const day = ('0' + date.getDate()).slice(-2);
        return `${year}-${month}-${day}`;
    }
    onDateChange(event: any) {
        const formattedDate = this.formatDate(event.target.value);
        this.RegisterForm.get('AuctionedDate')?.setValue(formattedDate);
    }
    OnSubmit() {
        throw new Error('Method not implemented.');
    }

    reg() {
        if (!this.LoginDetails || Object.keys(this.LoginDetails).length === 0) {
            this.router.navigate(['/Login']);
            return;
        } else {
            this.router.navigate(['/Home']).then(() => window.location.reload());
        }
    }




   
    convertToUppercase() {
        const control = this.RegisterForm.get('VehicleRegNo');
        if (control) {
            control.setValue(control.value.toUpperCase(), { emitEvent: false });
        }
    }

    // Method to fetch states from the server
    getstates() {
        debugger;
        var url = "api/FinancierLaw/FA_GetStates";
        this.generalService.Getdata(url).then(
            data => {
                debugger;
                this.Statedata = data;  // Store the fetched state data
            },
            err => {
                this.generalService.ShowAlert('ERROR', 'Something went wrong. Please try again later', 'error');
            }
        );
    }

    // Method called when a state is selected in the dropdown
    onStateSelection(value: string) {
        debugger;
        this.StateId = value;  // Store the selected state ID
        this.Getdistricts();  // Fetch districts based on the selected state
    }

    // Method to fetch districts based on the selected state ID
    Getdistricts() {
        debugger;
        if (!this.StateId) {
            this.generalService.ShowAlert('ERROR', 'State ID is missing. Please select a state.', 'error');
            return;
        }

        var UploadFile = new FormData();
        UploadFile.append("StateId", this.StateId);  // Append the state ID to the form data

        var url = "api/FinancierLaw/FA_GetDistricts";
        this.generalService.Postdata1(url, UploadFile).subscribe(
            data => {
                debugger;
                this.DistrictName = data;  // Store the fetched district data
                if (!this.DistrictName || this.DistrictName.length === 0) {
                    this.generalService.ShowAlert('ERROR', 'No districts found for the selected state.', 'error');
                }
            },
            err => {
                this.generalService.ShowAlert('ERROR', 'Something went wrong. Please try again later', 'error');
            }
        );
    }

    // Method called when a district is selected in the dropdown
    onDistrictSelection(value: string) {
        debugger;
        this.selectedDistrictName = value;  // Store the selected district name
    }


}

