<!-- 4th Form -->
 
<div id="pdf-content" class="pt-100">
    
    <div class="container mt-4">

        <!-- Next Page Header -->

               <div class="pdf-header">
            <div class="topheader">
                <h1 style="font-size: 27px; text-align:center; font-family: Arial Black; text-decoration: none;">
                    <b>{{loginDet[0].CompanyName}}</b>
                </h1>
                <p style="text-align:center; line-height: 1.2rem">
                    <b>{{loginDet[0].CompanyAddress}}</b><br />
                    <b>{{loginDet[0].Mobile}}</b>
                </p>
            </div>
        </div>


        <!--<div class="topheader">
            <h1 style="font-size: 27px; text-align:center; font-family: Arial Black; text-decoration: none;">
                <b>{{loginDet[0].CompanyName}}</b>
            </h1>
            <p style="text-align:center; line-height: 1.2rem">
                <b>{{loginDet[0].CompanyAddress}}</b><br />
                <b>{{loginDet[0].Mobile}}</b>
            </p>
        </div>-->




        <!--<div class="topheader">
        <h1 style="font-size: 27px; text-align:center; font-family: Arial Black; text-decoration: none;">
            <b>{{loginDet[0].CompanyName}}</b>
        </h1>
        <p style="text-align:center; line-height: 1.2rem">
            <b>{{loginDet[0].CompanyAddress}}</b><br />
            <b> {{loginDet[0].Mobile}}</b>
        </p>
    </div><br />-->
        <div class="header ">
            <h1 style="text-align:center;">PURCHASE RECEIPT / AGREEMENT</h1>
            <p class="float-end">Date: {{currentDate}}</p><br />

        </div>

        <div class="row">

            <div class="col-lg-12">
                <div class="seller-details">
                    <h3>Buyer Details:</h3>

                    <div class="row">
                        <div class="col-lg-12">
                            <b>{{buyer.BuyerName}}</b> S/o
                            <b>{{buyer.BuyerFatherName}} </b>,
                            age -<b>{{buyer.BuyerAge}}yrs </b>,
                            <b>Occupation - {{buyer.BuyerOccupation}}</b>,
                            residing at : Hno {{buyer.BuyerHNo}}, street no.{{buyer.BuyerStreet}}, {{buyer.BuyerColony}}, {{buyer.BuyerMandal}}, {{buyer.BuyerState}}, {{buyer.buyerPincode}}
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-12">
                <div class="buyer-details">
                    <h3>Seller Details:</h3>

                    <div class="col-lg-12">
                        <b>{{seller.SellerName}}</b> S/o
                        <b>{{seller.SellerFatherName}} </b>,
                        age - <b>{{seller.SellerAge}}yrs </b>,
                        <b>Occupation - {{seller.SellerOccupation}} </b>,
                        <!--{{seller.SellerName}} s/o  {{seller.SellerFatherName}}, age - {{seller.SellerAge}}, {{seller.SellerOccupation}},-->
                        residing at : Hno {{seller.SellerHNo}}, street no.{{seller.SellerStreet}}, {{seller.SellerColony}}, {{seller.SellerMandal}}, {{seller.SellerState}}, {{seller.SellerPincode}}
                    </div>


                </div>
            </div>
        </div>
        <div class="vehicle-details">
            <h3>Vehicle Details:</h3>


            <div class="row">

                <div class=" col-lg-12">
                    <table class="table">
                        <tbody>
                            <tr>
                                <td class="border">Registration No</td>
                                <td class="border"><b>{{Vehicle.VehicleRegistrationNo}}</b></td>
                                <td class="border widthh"></td>
                                <td class="border">Chassis No</td>
                                <td class="border"><b>{{Vehicle.VehicleChassisNo}}</b></td>
                                <td class="border widthh"></td>
                                <td class="border">Engine No</td>
                                <td class="border"><b>{{Vehicle.VehicleEngineNo}}</b></td>
                            </tr>
                            <tr>
                                <td class="border">TRANSPORT/NON TRANSPORT</td>
                                <td class="border"><b>{{Vehicle.TransportType}}</b></td>
                                <td class="border widthh"></td>
                                <td class="border">Vehicle Type</td>
                                <td class="border"><b>{{Vehicle.VehicleType}}</b></td>
                                <td class="border widthh"></td>
                                <td class="border">MODEL</td>
                                <td class="border"><b>{{Vehicle.VehicleModel}}</b></td>
                            </tr>
                            <tr>
                                <td class="border">FUEL</td>
                                <td class="border"><b>{{Vehicle.VehicleFuelType}}</b></td>
                                <td class="border widthh"></td>
                                <td class="border">RC Validity</td>
                                <td class="border"><b>{{Vehicle.RCValidity | date:'dd-MM-yyyy'}}</b></td>
                            </tr>
                        </tbody>
                    </table>
                </div>


                <div class="row">
                    <div class="col-lg-12">
                        <h3>
                            DOCUMENTS HISTORY:
                        </h3><br />
                    </div>
                    <div class="col-lg-12">
                        <table class="table">
                            <tbody>
                                <tr>
                                    <td class="border">FITNESS VALIDITY</td>
                                    <td class="border"><b>{{Vehicle.FitnessValidity | date:'dd-MM-yyyy'}}</b></td>
                                    <td class="border widthh"></td>
                                    <td class="border">PERMIT No</td>
                                    <td class="border"><b>{{Vehicle.PermitNumber}}</b></td>
                                    <td class="border widthh"></td>
                                    <td class="border">Permit Validity</td>
                                    <td class="border"><b>{{Vehicle.PermitValidity | date:'dd-MM-yyyy'}}</b></td>
                                </tr>
                                <tr>
                                    <td class="border">METER SEAL VALIDITY</td>
                                    <td class="border"><b>{{Vehicle.MeterSealValidity | date:'dd-MM-yyyy'}}</b></td>
                                    <td class="border widthh"></td>
                                    <td class="border">CNG/LPG TANK VALIDITY</td>
                                    <td class="border"><b>{{Vehicle.CNGLPGValidity | date:'dd-MM-yyyy'}}</b></td>
                                    <td class="border widthh"></td>
                                    <td class="border">E.V. BATTERY WARRANTY</td>
                                    <td class="border"><b>{{Vehicle.EVBatteryValidity | date:'dd-MM-yyyy'}}</b></td>
                                </tr>
                                <tr>
                                    <td class="border">TAX VALIDITY</td>
                                    <td class="border"><b>{{Vehicle.TaxValidity | date:'dd-MM-yyyy'}}</b></td>
                                    <td class="border widthh"></td>
                                </tr>
                            </tbody>
                        </table>

                    </div>
                </div>

            </div>
            <div class="mt-5 "></div>
            <div class="sale-terms " *ngIf="isGeneratingPDF" style="page-break-before: always;">
                <div class="pdf-header mt-3">
                    <div class="topheader">
                        <h1 style="font-size: 27px; text-align:center; font-family: Arial Black; text-decoration: none;">
                            <b>{{loginDet[0].CompanyName}}</b>
                        </h1>
                        <p style="text-align:center; line-height: 1.2rem">
                            <b>{{loginDet[0].CompanyAddress}}</b><br />
                            <b>{{loginDet[0].Mobile}}</b>
                        </p>
                    </div>
                </div>
            </div>

            <div class=" sale-terms ">
                <h3 class="mt-5">Sale Terms:</h3>
                <p>
                    1. The seller confirms that the vehicle is free from any finance or loan obligations/with finance.
                    2. The seller warrants that the vehicle is free from any RTA, police, traffic costs, or civil and
                    criminal cases.<br />
                    3. The buyer acknowledges that the vehicle is being purchased in its current condition
                </p>
            </div>
            <div class="additional-decorations">
                <h3> Additional declarations:</h3>
                <p> 1.	The seller has provided all necessary documents and information </p>
                <p> 2.	The seller will come to the RTO office physically if required for the vehicle transfer </p>
                <p> 3.	The buyer has inspected the vehicle and is satisfied with its condition </p>
            </div>
            <div class="Finance_Details">
                <h3>Finance Details:</h3>





                <div class="row">
                    <div class="col-lg-9 mt-2 "> 1.Loan Amount (if applicable): </div>
                    <div class="col-lg-3 mt-2 ">₹ <span class="blank-line"></span> </div>
                    <div class="col-lg-9 mt-2 ">2.Amount Paid: </div>
                    <div class="col-lg-3 mt-2 "><b>{{Vehicle.PaidAmount}}/-</b>. </div>
                    <div class="col-lg-9 mt-2 ">3.Total  Interest Payable and Late Payment (if applicable):</div>
                    <div class="col-lg-3 mt-2 "> ₹ <span class="blank-line"></span> </div>

                </div>



            </div>
            <div class=" Sale-Consideration">

                <h3>Sale Consideration:</h3>
                <p>
                    The seller has sold the vehicle to the buyer for ₹
                </p>
            </div>
            <br />
            <br /><br />
            <div class="last">
                <div class="column">
                    <h3 class="ms-3">Seller's Sign</h3>
                    <div class="box">
                        <img [src]="sellerSignatureUrl" alt="pic" *ngIf="sellerSignatureUrl; else defaultSellerImage" />
                        <ng-template #defaultSellerImage>
                            <img src="/assets/img/pic-1.jpg" alt="default-pic" />
                        </ng-template>
                    </div>
                </div>

                <div class="column">
                    <h3 class="ms-3">Buyer's Sign</h3>
                    <div class="box">
                        <img [src]="buyerSignatureUrl" alt="pic" *ngIf="buyerSignatureUrl; else defaultBuyerImage" />
                        <ng-template #defaultBuyerImage>
                            <img src="/assets/img/pic-1.jpg" alt="default-pic" />
                        </ng-template>
                    </div>
                </div>

                <div class="column">
                    <h3 class="ms-3">Witness's Sign</h3>
                    <div class="box">
                        <img [src]="witnessSignatureUrl" alt="pic" *ngIf="witnessSignatureUrl; else defaultWitnessImage" />
                        <ng-template #defaultWitnessImage>
                            <img src="/assets/img/pic-1.jpg" alt="default-pic" />
                        </ng-template>
                    </div>
                </div>

                <div class="column">
                    <h3 class="ms-3">Vehicle Photo</h3>
                    <div class="box">
                        <img [src]="vehiclePhotoUrl" alt="pic" *ngIf="vehiclePhotoUrl; else defaultVehicleImage" />
                        <ng-template #defaultVehicleImage>
                            <img src="/assets/img/pic-1.jpg" alt="default-pic" />
                        </ng-template>
                    </div>
                </div>


            </div>

        </div>

    </div>

    <div class="row">
        <div class="col-lg-8 "></div>
        <div class="col-lg-1 "  *ngIf="isGeneratingbutton">
            <button (click)="navigateTOeditform(Fdata[0].RefNo )" class="pdf"><i class="fa fa-edit"></i> Edit</button>
        </div>
        <div class="col-lg-3" *ngIf="isGeneratingbutton">
            <button (click)="downloadPDF()" class="pdf"><i class="fa fa-download"></i> Generate PDF</button>
        </div>
    </div>
</div>
