<div class="pt-100">
    <div class="container-fluid mt-5 mtp-5">
        <div class="row">
                <div id="demo" class="carousel slide carousell" data-bs-ride="carousel">
                    <div class="carousel-indicators">
                        <button type="button" data-bs-target="#demo" data-bs-slide-to="0" class="active"></button>
                        <button type="button" data-bs-target="#demo" data-bs-slide-to="1"></button>
                        <button type="button" data-bs-target="#demo" data-bs-slide-to="2"></button>
                    </div>
                    <div class="carousel-inner">
                        <div class="carousel-item active">
                            <img src="../../../assets/img/B1.webp" alt="B1" class="d-block w-100">
                        </div>
                        <div class="carousel-item">
                            <img src="../../../assets/img/B2.webp" alt="B2" class="d-block w-100">
                        </div>
                        <div class="carousel-item">
                            <img src="../../../assets/img/B3.webp" alt="B3" class="d-block w-100">
                        </div>
                    </div>
                    <button class="carousel-control-prev" type="button" data-bs-target="#demo" data-bs-slide="prev">
                        <span class="carousel-control-prev-icon"></span>
                    </button>
                    <button class="carousel-control-next" type="button" data-bs-target="#demo" data-bs-slide="next">
                        <span class="carousel-control-next-icon"></span>
                    </button>
                </div>
        </div>
    </div>

    <br />
    <div class="container ">
        <div class="row">
            <div class="col-lg-9">

                <div class="row" >
                    <div class="col-lg-3 col-xs-6" (click)="onCardClick(1)">
                        <div class="NoticeCards">
                            <div class="ImageContainer">
                                <img src="../../../assets/img/RibbonImg.png" alt="ribbon" class="NoticeImage">
                            </div>
                            <div class="NHN">Conciliation Notice</div>
                            <div class="NoticeCardName">Notice for <b>Conciliation Process</b> to Settle Overdue Loan</div>
                        </div>
                    </div>

                    <div class="col-lg-3 col-xs-6" (click)="onCardClick(2)">
                        <div class="NoticeCards">
                            <div class="ImageContainer">
                                <img src="../../../assets/img/RibbonImg.png" alt="ribbon1" class="NoticeImage">
                            </div>
                            <div class="NHN">Final Notice</div>
                            <div class="NoticeCardName">Final Opportunity to Settle Rs._ to <b>Avoid Vehicle Auction.</b></div>
                        </div>
                    </div>

                    <div class="col-lg-3 col-xs-6" (click)="onCardClick(3)">
                        <div class="NoticeCards">
                            <div class="ImageContainer">
                                <img src="../../../assets/img/RibbonImg.png" alt="ribbon2" class="NoticeImage">
                            </div>
                            <div class="NHN">Vehicle Auctioned Notice</div>
                            <div class="NoticeCardName">Vehicle Auctioned for Rs._ <b>Immediate Payment of Remaining Rs._ Required</b></div>
                        </div>
                    </div>

                    <div class="col-lg-3 col-xs-6" (click)="onCardClick(4)">
                        <div class="NoticeCards">
                            <div class="ImageContainer">
                                <img src="../../../assets/img/RibbonImg.png" alt="ribbon3" class="NoticeImage">
                            </div>
                            <div class="NHN">Pledged Auction Notice</div>
                            <div class="NoticeCardName">Final Notice: Settle Loan Under Pawn Brokers Act 2002 to <b>Avoid Jewelry Auction.</b></div>
                        </div>
                    </div>

                    <div class="col-lg-3 col-xs-6" (click)="onCardClick(5)">
                        <div class="NoticeCards">
                            <div class="ImageContainer">
                                <img src="../../../assets/img/AppLogo.png" alt="applogo" class="NoticeImage">
                            </div>
                            <div class="NHN">No Objection Letter</div>
                            <div class="NoticeCardName">Confirmation of Full Payment for Vehicle Loan – No Objection to Cancel Hire Purchase</div>
                        </div>
                    </div>

                    <div class="col-lg-3 col-xs-6" (click)="onCardClick(6)">
                        <div class="NoticeCards">
                            <div class="ImageContainer">
                                <img src="../../../assets/img/AppLogo.png" alt="applogo1" class="NoticeImage">
                            </div>
                            <div class="NHN">Agreement Form</div>
                            <div class="NoticeCardName">Authorization for Consultant to <b>Sell Vehicle</b> and Manage Finance Agreement.</div>
                        </div>
                    </div>

                    <div class="col-lg-3 col-xs-6" (click)="onCardClick(7)">
                        <div class="NoticeCards">
                            <div class="ImageContainer">
                                <img src="../../../assets/img/AppLogo.png" alt="applogo2" class="NoticeImage">
                            </div>
                            <div class="NHN">Authorization Form</div>
                            <div class="NoticeCardName">
                                Request for Issuance of <b>Fresh RC for Vehicle</b>No._ Due to Absconding Owner and Authorization
                                for Inspection Coordination.
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-3 col-xs-6" (click)="onCardClick(8)">
                        <div class="NoticeCards">
                            <div class="ImageContainer">
                                <img src="../../../assets/img/AppLogo.png" alt="applogo3" class="NoticeImage">
                            </div>
                            <div class="NHN">Surrender Letter</div>
                            <div class="NoticeCardName">Formal Surrender Notice for Vehicle Loan</div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-xs-6" (click)="onCardClick(9)">
                        <div class="NoticeCards">
                            <div class="ImageContainer">
                                <img src="../../../assets/img/AppLogo.png"  alt="applogo4" class="NoticeImage">
                            </div>
                            <div class="NHN">Form - 35</div>
                            <div class="NoticeCardName">Request for Cancellation of Hire-Purchase/Lease Agreement Endorsement in Vehicle Registration.</div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-xs-6" (click)="onCardClick(10)">
                        <div class="NoticeCards">
                            <div class="ImageContainer">
                                <img src="../../../assets/img/AppLogo.png" alt="applogo" class="NoticeImage">
                            </div>
                            <div class="NHN">Form - 36</div>
                            <div class="NoticeCardName">Request for Cancellation of Hire-Purchase/Lease Agreement Endorsement in Vehicle Registration.</div>
                        </div>
                    </div>
                </div>

            </div>

            <div class="col-lg-3 " >


                <div>
                    <img class="home_images" src="../../assets/img/loginimage.webp" alt="login_image" />
                    <img class="qr_home" src="../../assets/img/qr.webp" alt="qrImage" />
                    <p class="qr_text">NOTE: Please scan the QRcode to download Android Mobile App. </p>
                </div>
           <!--<div class="rightcard">
               <div class="container  scroll-container">
                   <div class="text-center">
                       <img src="../../../assets/img/A1.png" class="left-card-Image">
                       <div>
                           <h3 class="FLName">
                               "Vehicle Reg No: TS30AS70" <br>
                           </h3>
                       </div>
                   </div>
                   <div class="text-center">
                       <img src="../../../assets/img/s1.png" class="left-card-Image">
                       <div>
                           <h3 class="FLName">
                               "Vehicle Reg No: TS4070" <br>
                           </h3>
                       </div>
                   </div>
                   <div class="text-center">
                       <img src="../../../assets/img/SC1.png" class="left-card-Image">
                       <div>
                           <h3 class="FLName">
                               "Vehicle Reg No: AP12070" <br>
                           </h3>
                       </div>
                   </div>
               </div>
           </div>-->
       </div>
        </div>
    </div>









   
    <div class="container-fluid container-fluid1 orange-bg mt-5" id="section1">
        <div class="container mb-5 ">
            <h3 class="mb-2  text-white">How It Works</h3>
            <p class="text-white">
                By leveraging our app, financiers and lawyers can now efficiently manage the legal process, reducing the time and effort required to issue notices and initiate proceedings.
                Our goal is to provide quality legal aid and support through this innovative platform.
            </p>
        </div>
        <div class="container">
            <div class="row">
                <div class="col-lg-4   triangle">
 
                    <div class="HIW">
                        <h2 class="HIWN bold">How It Works</h2>
                    </div>

                </div>
                <div class="col-lg-8 mlt">

                    <div class="row">
                        <div class="step col-lg-2">
                            <div class="step-header">Step 1</div>
                            <img src="../../../assets/img/reg.png" alt="regImg" class="NoticeImage">
                            <div class="step-content">Register Financiers & Pawnbrokers</div>
                        </div>
                        <div class="arrow">&#10132;</div>

                        <div class="step col-lg-2">
                            <div class="step-header">Step 2</div>
                            <img src="../../../assets/img/sub.png" alt="subImg" class="NoticeImage">
                            <div class="step-content">Need to Take Subscription</div>
                        </div>
                        <div class="arrow">&#10132;</div>

                        <div class="step col-lg-2">
                            <div class="step-header">Step 3</div>
                            <img src="../../../assets/img/stp4.png" alt="stp4Img" class="NoticeImage">
                            <div class="step-content">Generate 12 Notices Under the Advocate's.</div>
                        </div>
                        <div class="arrow">&#10132;</div>

                        <div class="step col-lg-2">
                            <div class="step-header">Step 4</div>
                            <img src="../../../assets/img/stp3.png"  alt="image" class="NoticeImage">
                            <div class="step-content">Financier & Pawnbrokers able to generate the Legal notice</div>
                        </div>
                    </div>

                </div>

            </div>
        </div>
    </div>


    <div class="container-fluid">
        <div class="container mt-5 ">
            <div class="row">

                <div class="col-lg-6 mb-3">

                    <img src="assets\img\aboutt.jpg" alt="aboutImg" />
                </div>
                <div class="col-lg-6 caption-top p-5 card1">
                    <h3 class="brown">
                        <strong>About Finance Legal App</strong></h3>
                    <ul class="mt-3 p-2">
                        <li><strong>Consulting a lawyer:</strong> Simplify the consultation process with integrated tools.</li>
                        <li><strong>Presenting customer transaction history:</strong> Efficiently manage and present transaction records.</li>
                        <li><strong>Initiating legal proceedings:</strong> Streamline the initiation of legal actions.</li>
                    </ul>
                </div>
            </div><br /><br />

                        <div class="row">
                            <div class="col-lg-6 caption-top-legal  p-5  card1 order-lg-1 order-2">
                                <h3 class="brown">
                                    <strong>Experienced Legal Advocate</strong>
                                </h3>
                                <div class="mt-3 p-2">
                                    <strong>Sri V.V.S. Satyanarayana</strong> has been practicing law for the past <strong>30 years</strong>, specializing in both <strong>Criminal and Civil cases</strong>. He has represented clients in various courts, including civil courts in Hyderabad, Secunderabad, Ranga Reddy District, Medchal Malkajgiri, as well as the <strong>Hon'ble High Court</strong> and various <strong>tribunals </strong>across these districts and the state of Telangana. With extensive experience in both civil and criminal matters, he is well-versed in defending a wide range of cases.
                                </div>

                            </div>
                            <div class="col-lg-6 order-lg-2 order-1 d-flex justify-content-center align-items-start text-center">
                                <div class="w-50" style="padding: 10px; position: relative;">
                                    <img src="assets/img/advocate_withoutbg.png" alt="aboutImg" class="styled-image" style="width: 100%; border-radius: 8px;" />
                                    <h3 class="brown  mt-2 underline-hover " style="font-size: 23px">
                                        <strong>Sri V.V.S.Satyanarayana</strong>
                                    </h3>
                                </div>
                            </div>
                        </div>



    </div>
            <!--<div class="row mt-5">
                <div class="col-lg-12 caption-top-legal p-2 ">
                    <div class=" d-flex justify-content-center align-items-center">
                        <div class="border-3 border-light-subtle" style="background-color: #f0f0f0; padding: 10px; position: relative;">
                            <img src="assets/img/advocate_withoutbg.png" alt="aboutImg" class="styled-image" style="width: 50%; border-radius: 8px;" />
                        </div>
                    </div>
                    <div class="  text-center">                        
                        <h3 class="brown  mt-2 underline-hover ">
                            V.V.S. Satyanarayana
                        </h3>
                        </div>
                        <ul class="mt-3 p-2  text-center">
                            <li><strong>V.V.S.Satyanarayana</strong> his Profession since last 30 years and defending all types cases in both <strong>criminal and civil</strong></li>
                            <li>civil courts, Hyderabad, Secunderabad, Ranga Reddy District, Medchal Malkajgiri including <strong>Hon'ble High Court</strong> and all</li>
                            <li>and all <strong>Tribunals</strong> in the said districts and telangana states having vast experience in Civil and criminal cases.</li>
                        </ul>

                    </div>
            </div>-->
        </div>
    </div>


