<div class="container ptb-100 mtb-5">
    <div id="pdf-content"  class=" mt-5">
        <div class="row header">
            <div class="col-sm-3 col-md-3 col-3  d-flex justify-content-center align-items-center">
                <img src="../../../assets/img/Justies.png" alt="justices" class="justies">
            </div>
            <div class="col-sm-5 col-md-5 col-5   advocate ">
                <h6> <b>BY WAY OF SPEED POST</b></h6>
                <div class="middle">
                    <img src="../../../assets/img/advocate1.png" alt="advocate" class="ribbon">
                </div>
            </div>
            <div class="col-sm-4 col-md-4 col-4  text-center right no-gap ">
                <p class="text-black"> <b>VVS.SATYANARAYANA </b></p>
                <p class="text-black"> <b>Advocates </b></p>
                <p class="text-black"> <b>Legal Consultants & Solicitors </b></p>
                <p class="text-black"> <b>Cell No.9381417022 </b></p>
            </div>
        </div> <br />
        <div class="border-top1"></div>
        <div class="border-top2"></div>
        <h5 class="headerlegal mt-3"> Legal Notice for Vehicle Transfer</h5><br />
        <div class="float-end mb-2">Date:<b>{{ Fdata[0].CreatedDate | date:'dd-MM-yyyy' }}</b></div>
        <br />
        <ul>
            <li>To,</li>
            <li>{{ Fdata[0].BorrowerName}}</li>
            <li>{{ Fdata[0].BarrowerAddress}}</li>
            <li>{{ Fdata[0].BarrowerMobile}}</li>
        </ul>
        <!--RIGHT SIDE TO-->
        <!--<div class="d-flex justify-content-end">
            <ul class="list-unstyled text-right">
                <li>To</li>
                <li>Naushad</li>
                <li>9807907678</li>
                <li>Secunderabad</li>
            </ul>
        </div>-->

        <br />
        <p> Registration No.<span style="color:red;">{{notice5}} </span></p>


        <div class="mt-3">
            <p> We represent Hasmuk Motors, Khairatabad, a commission agent for buying and selling second-hand vehicles.</p>

            <p>On Date: <span style="color:red;">{{Fdata[0].DueDate | date:'dd-MM-yyyy'}} </span> you sold the above-mentioned vehicle to our client. As per the sale agreement, you agreed to:</p>

            <div class="indent">
                <p>- Appear before the Regional Transport Officer (RTA) upon our client's request</p>
                <p> - Transfer the vehicle to the person notified by our client</p>
                <p> - Collect all monies due from our client</p>
            </div>
            <p>Despite multiple requests, you have failed to appear for the vehicle transfer. <br /> Therefore, we hereby request you to appear on date: <span style="color:red;">{{Fdata[0].SeizedDate | date:'dd-MM-yyyy'}}</span> for the transfer.</p>

            <p class="mt-2"><b>Important:</b></p><p> Failure to Appear Failure to comply with this request will result in criminal and civil action being initiated against you . Please confirm your attendance by [date].</p>
        </div>
        <div class="d-flex justify-content-end ">
            <div class=" last">
                <p class="mt-4 ">Yours Sincerely,</p>
                <p>
                    <img src="/assets/img/Lawyers_Signature-removebg-preview.png " alt="signature" />
                </p>
                <p>VVS SATYA NARAYANA</p>
                <p>Advocate</p>

                <!--<p>
                    [Your Title]
                </p>
                <p>
                    [Hasmuk Motors]
                </p>
                <p>
                    [Contact Information]
                </p>-->
            </div>
        </div>
        <div class="single-line-container content"></div>
        <div class="end">
            <p style="color: red;">Address to correspond: secunderabad, 22-345A, malkajgiri, vaninagar</p>
        </div>
        </div>

    </div>
    <div class="row">
        <div class="col-lg-8 ">
        </div>
        <div class="col-lg-1 ">
            <button (click)="navigateTOeditform(Fdata[0].RefNo )" class="pdf"><i class="fa fa-edit"></i> Edit  </button>
        </div>
        <div class="col-lg-3">
            <button (click)="downloadPDF()" class="pdf"><i class="fa fa-download"></i> Generate PDF  </button>
        </div>
    </div>
    <!--<div class="single-line-container content"></div>
    <div class="end">
      <p style="color: red;">Address to correspond: secunderabad, 22-345A, malkajgiri, vaninagar</p>
    </div>-->



