<!-- 4th Form -->
<div id="pdf-content" class="mt-1 ptb-100">
    <div class="container pt-5 mt-2">
        <div class="topheader">
            <h1 style="font-size: 27px; text-align:center; font-family: Arial Black; text-decoration: none;">
                <b>{{loginDet[0].CompanyName}}</b>
            </h1>
            <p style="text-align:center; line-height: 1.2rem">
                <b>{{loginDet[0].CompanyAddress}}</b><br />
                <b> {{loginDet[0].Mobile}}</b>
            </p>
        </div><br />
              <div class="header">
                  <h1 style="text-align:center;">VEHICLE SALE AGREEMENT</h1>
                  <p class="float-end">Date: <b>{{currentDate}}</b></p><br />

              </div>
        <div class="row">
            <div class="col-lg-12">
                <div class="buyer-details">      
                    <h3>Seller Details:</h3>   
                    <div class="row">
                        <div class="col-lg-12">
                            <b>{{seller.SellerName}}</b> S/o
                            <b>{{seller.SellerFatherName}} </b>,
                            <!--<b>{{seller.SellerAge}}yrs </b>,-->age - {{seller.SellerAge}},
                            <b>Occupation - {{seller.SellerOccupation}} </b>,
                            <!--{{seller.SellerName}} S/o  {{seller.SellerFatherName}},-->  {{seller.SellerOccupation}},
                            residing at : Hno {{seller.SellerHNo}}, street no.{{seller.SellerStreet}}, {{seller.SellerColony}}, {{seller.SellerMandal}}, {{seller.SellerState}}, {{seller.SellerPincode}}
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-12">
                <div class="seller-details">
                    <h3>Buyer Details:</h3>
                    <div class="row">
                        <div class="col-lg-12">
                            <b>{{buyer.BuyerName}}</b> S/o
                            <b>{{buyer.BuyerFatherName}} </b>,
                            age - <b>{{buyer.BuyerAge}}yrs </b>,
                            <b>Occupation - {{buyer.BuyerOccupation}}</b>,
                            residing at : Hno {{buyer.BuyerHNo}}, street no.{{buyer.BuyerStreet}}, {{buyer.BuyerColony}}, {{buyer.BuyerMandal}}, {{buyer.BuyerState}}, {{buyer.buyerPincode}}
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <br />
        <div class="row">
            <div class="col-lg-12">
                <div class="vehicle-details">
                    <h3>Vehicle Details: Transport / Non-Transport:</h3>


                    <br />
                    <div class="row">

                        <div class="col-lg-12">
                            <table class="table">
                                <tbody>
                                    <tr>
                                        <td class="border">Registration No</td>
                                        <td class="border"><b>{{Vehicle.VehicleRegistrationNo}}</b></td>
                                        <td class="border widthh"></td>
                                        <td class="border">Chassis No</td>
                                        <td class="border"><b>{{Vehicle.VehicleChassisNo}}</b></td>
                                        <td class="border widthh"></td>
                                        <td class="border">Engine No</td>
                                        <td class="border"><b>{{Vehicle.VehicleEngineNo}}</b></td>
                                    </tr>
                                    <tr>
                                        <td class="border">Vehicle Type</td>
                                        <td class="border"><b>{{Vehicle.VehicleType}}</b></td>
                                        <td class="border widthh"></td>
                                        <td class="border">MODEL</td>
                                        <td class="border"><b>{{Vehicle.VehicleModel}}</b></td>
                                        <td class="border widthh"></td>
                                        <td class="border">FUEL</td>
                                        <td class="border"><b>{{Vehicle.VehicleFuelType}}</b></td>
                                    </tr>
                                    <tr>
                                        <td class="border">FITNESS VALIDITY</td>
                                        <td class="border"><b>{{Vehicle.FitnessValidity | date:'dd-MM-yyyy'}}</b></td>
                                        <td class="border widthh"></td>
                                        <td class="border">PERMIT No</td>
                                        <td class="border"><b>{{Vehicle.PermitNumber}}</b></td>
                                        <td class="border widthh"></td>
                                        <td class="border">Permit Validity</td>
                                        <td class="border"><b>{{Vehicle.PermitValidity | date:'dd-MM-yyyy'}}</b></td>
                                    </tr>
                                    <tr>
                                        <td class="border">RC Validity</td>
                                        <td class="border"><b>{{Vehicle.RCValidity | date:'dd-MM-yyyy'}}</b></td>
                                        <td class="border widthh"></td>
                                        <td class="border">METER SEAL VALIDITY</td>
                                        <td class="border"><b>{{Vehicle.MeterSealValidity | date:'dd-MM-yyyy'}}</b></td>
                                        <td class="border widthh"></td>
                                        <td class="border">CNG/LPG TANK VALIDITY</td>
                                        <td class="border"><b>{{Vehicle.CNGLPGValidity | date:'dd-MM-yyyy'}}</b></td>
                                    </tr>
                                    <tr>
                                        <td class="border">E.V. BATTERY WARRANTY</td>
                                        <td class="border"><b>{{Vehicle.EVBatteryValidity | date:'dd-MM-yyyy'}}</b></td>
                                        <td class="border widthh"></td>
                                        <td class="border">TAX VALIDITY</td>
                                        <td class="border"><b>{{Vehicle.TaxValidity | date:'dd-MM-yyyy'}}</b></td>
                                    </tr>
                                </tbody>
                            </table>

                        </div>

                        <!--<div class="col-lg-2 border">Registration No</div>
            <div class="col-lg-2 border"><b>{{Vehicle.VehicleRegistrationNo}}</b></div>
            <div class="col-lg-2 border">Chassis No</div>
            <div class="col-lg-2 border"><b>{{Vehicle.VehicleChassisNo}}</b></div>
            <div class="col-lg-2 border">Engine  No</div>
            <div class="col-lg-2 border"><b>{{Vehicle.VehicleEngineNo}}</b></div>
            <div class="col-lg-2 border">Vehicle Type </div>
            <div class="col-lg-2 border"><b>{{Vehicle.VehicleType}}</b></div>
            <div class="col-lg-2 border">MODEL</div>
            <div class="col-lg-2 border"><b>{{Vehicle.VehicleModel}}</b></div>
            <div class="col-lg-2 border">FUEL  </div>
            <div class="col-lg-2 border"><b>{{Vehicle.VehicleFuelType}}</b></div>
            <div class="col-lg-2 border">FITNESS VALIDITY</div>
            <div class="col-lg-2 border"><b>{{Vehicle.FitnessValidity  | date:'dd-MM-yyyy'}}</b></div>
            <div class="col-lg-2 border">PERMIT  No</div>
            <div class="col-lg-2 border"><b>{{Vehicle.PermitNumber}}</b></div>
            <div class="col-lg-2 border">Permit Validity</div>
            <div class="col-lg-2 border"><b>{{Vehicle.PermitValidity  | date:'dd-MM-yyyy'}}</b></div>
            <div class="col-lg-2 border">RC Validity</div>
            <div class="col-lg-2 border"><b>{{Vehicle.RCValidity  | date:'dd-MM-yyyy'}}</b></div>
            <div class="col-lg-2 border">METER SEAL VALIDITY</div>
            <div class="col-lg-2 border"><b>{{Vehicle.MeterSealValidity  | date:'dd-MM-yyyy'}}</b></div>
            <div class="col-lg-2 border">CNG/LPG TANK VALIDITY</div>
            <div class="col-lg-2 border"><b>{{Vehicle.CNGLPGValidity  | date:'dd-MM-yyyy'}}</b></div>
            <div class="col-lg-2 border">E.V. BATTERY WARRANTY</div>
            <div class="col-lg-2 border"><b>{{Vehicle.EVBatteryValidity  | date:'dd-MM-yyyy'}}</b></div>
            <div class="col-lg-2 border">TAX VALIDITY</div>
            <div class="col-lg-2 border"><b>{{Vehicle.TaxValidity  | date:'dd-MM-yyyy'}}</b></div>-->
                    </div>


                </div>
                <br />


                <div class="sale-terms">
                    <h3>Sale Terms:</h3>
                    <p>
                        1. The Seller agrees to sell the vehicle to the Buyer for ₹<b>{{Vehicle.VehiclePrice}}</b>.<br />
                        2. The Buyer acknowledges that the vehicle is being sold in its current condition.<br />
                        3. The Seller warrants that the vehicle is free from any finance or loan obligations / with finance.<br />
                        4. The Seller warrants that the vehicle is free from any RTA, police, traffic costs, or civil and criminal cases.<br />
                        5. Vehicles once sold will not be taken back or exchanged, and papers validation must be done in 30 days.<br />
                        6. No guarantee on sale of old vehicle parts and repairs.<br />
                        7. Transfer of ownership should be done by the purchaser within 30 days.<br />
                    </p>
                </div><br />
                <div class="payment-terms">
                    <h3>Payment Terms:</h3>
                    <p>
                        1. The Buyer shall pay the sale price in full by Payment Method, e.g., cash, bank transfer / With Finance.<br />
                        2. The payment shall be made on or before (Date:<b> {{Vehicle.PaymentDueDate  | date:'dd-MM-yyyy'}})</b>.<br />
                    </p>
                    <h3>Finance Details:</h3>

                    <div class="row">
                        <div class="col-lg-4"> 1. Total Cost of Vehicle Rs. </div>
                        <div class="col-lg-8"><b>{{Vehicle.VehiclePrice}}/-</b>. </div>
                        <div class="col-lg-4"> 2. Amount Paid by Buyer Rs.</div>
                        <div class="col-lg-8"><b>{{Vehicle.PaidAmount}}/-</b>. </div>
                        <div class="col-lg-4"> 3. Remaining amount financed Rs.</div>
                        <div class="col-lg-8"><b>{{Vehicle.RemainingAmount}}/-</b>. </div>
                        <div class="col-lg-4"> 4. Name of the Financier:</div>
                        <div class="col-lg-8"><b>{{Vehicle.FinancierName}}</b>. </div>
                        <div class="col-lg-4"> 5. Address:</div>
                        <div class="col-lg-8"><b> {{Vehicle.FinancierAddress}}</b>. </div>
                    </div>

                    <!--<p>
            1. Total Cost of Vehicle Rs.<b>{{Vehicle.VehiclePrice}}/-</b>.<br />
            2. Amount Paid by Buyer Rs.<b>{{Vehicle.PaidAmount}}/-</b>.<br />
            3. Remaining amount financed Rs.<b>{{Vehicle.RemainingAmount}}/-</b>.<br />
            4. Name of the Financier:  <b>{{Vehicle.FinancierName}}</b>.<br />
            5. Address:<b> {{Vehicle.FinancierAddress}}</b>.
        </p>-->
                </div>

                <div class="payment-details" style="position: relative">
                    <br />
                    <h3>Payment Details:</h3>
                    <p>
                        Cash Received Advanced Rs.<b>{{Vehicle.PaidAmount}}</b>/- Due amount Rs.<b>{{Vehicle.RemainingAmount}}</b>/- Pending Due date: <b>{{Vehicle.PaymentDueDate  | date:'dd-MM-yyyy'}}</b>
                    </p>
                    <span style="text-align:end; position:absolute; right:20px;">P.T.O</span><br />
                </div>
                <div class="buyer-details-Signature">
                    <h3>Buyer Signature:</h3>
                </div>

                <div class="sale-terms " *ngIf="isGeneratingPDF">
                    <div class="pdf-header mt-3">
                        <div class="topheader">
                            <h1 style="font-size: 27px; text-align:center; font-family: Arial Black; text-decoration: none;">
                                <b>{{loginDet[0].CompanyName}}</b>
                            </h1>
                            <p style="text-align:center; line-height: 1.2rem">
                                <b>{{loginDet[0].CompanyAddress}}</b><br />
                                <b>{{loginDet[0].Mobile}}</b>
                            </p>
                        </div>
                    </div>
                </div>
                <!-- Part 2 should start from the next page -->
                <div class="Delivery-Note sale-terms mt-5">
                    <h3 style="text-align:center;">PART-<span style=" font-weight: 500; font-family: 'Poppins';"><b>&#8545;</b></span> Delivery Note</h3>
                </div>

                <div class="TRANSFER-OF-OWNERSHIP">
                    <h3>Transfer of Ownership:</h3>
                    <p>
                        1. The Seller shall transfer the ownership of the vehicle to the Buyer upon receipt of the sale price.<br />
                        2. The Buyer shall be responsible for registering the vehicle in their name.
                    </p>
                </div>
                <br />
                <div class="WARRANTIES-AND-REPRESENTATIONS">
                    <h3>Warranties and Representations:</h3>
                    <p>
                        1. The Seller warrants that the vehicle is free from any defects or liabilities.<br />
                        2. The Buyer acknowledges that they have inspected the vehicle and are satisfied with its present condition.
                    </p>
                </div>
                <br />
                <div class="DISPUTE-RESOLUTION">
                    <h3>Dispute Resolution:</h3>
                    <p>Once the vehicle is sold, there will be no more opportunities for any disputes.</p>
                </div>
                <br />
                <div class="ACKNOWLEDGMENT">
                    <h3>Acknowledgment:</h3>
                    <p>
                        By signing below, both parties acknowledge that they have read, understand, and agree to the terms and conditions of this Agreement, and the buyer has received the vehicle.
                    </p>
                </div>
                <br />
                <div class="last">
                    <div class="column">
                        <h3>Seller's Sign</h3>
                        <div class="box">
                            <img [src]="sellerSignatureUrl" alt="pic" *ngIf="sellerSignatureUrl; else defaultSellerImage" />
                            <ng-template #defaultSellerImage>
                                <img src="/assets/img/pic-1.jpg" alt="default-pic" />
                            </ng-template>
                        </div>
                    </div>

                    <div class="column">
                        <h3>Buyer's Sign</h3>
                        <div class="box">
                            <img [src]="buyerSignatureUrl" alt="pic" *ngIf="buyerSignatureUrl; else defaultBuyerImage" />
                            <ng-template #defaultBuyerImage>
                                <img src="/assets/img/pic-1.jpg" alt="default-pic" />
                            </ng-template>
                        </div>
                    </div>

                    <div class="column">
                        <h3>Witness's Sign</h3>
                        <div class="box">
                            <img [src]="witnessSignatureUrl" alt="pic" *ngIf="witnessSignatureUrl; else defaultWitnessImage" />
                            <ng-template #defaultWitnessImage>
                                <img src="/assets/img/pic-1.jpg" alt="default-pic" />
                            </ng-template>
                        </div>
                    </div>

                    <div class="column">
                        <h3>Vehicle Photo</h3>
                        <div class="box">
                            <img [src]="vehiclePhotoUrl" alt="pic" *ngIf="vehiclePhotoUrl; else defaultVehicleImage" />
                            <ng-template #defaultVehicleImage>
                                <img src="/assets/img/pic-1.jpg" alt="default-pic" />
                            </ng-template>
                        </div>
                    </div>

                    <!--<div class="column">
            <h3>Buyer's Sign</h3>
            <div class="box"><img src="/assets/img/pic-1.jpg" alt="pic" /></div>
        </div>-->
                    <br />
                    <!--<div class="column">
                <h3>Witness Sign</h3>
                <div class="box"><img src="/assets/img/pic-1.jpg" alt="pic" /></div>
            </div>
            <br />
            <div class="column">
                <h3>Vehicle Photo</h3>
                <div class="box"><img src="/assets/img/bike.jpg" alt="bike" /></div>
            </div>
        </div>-->
                    <br />

                </div>
                <p><b>Final Receipt:</b> Total Amount of Rs.<b>{{Vehicle.VehiclePrice}}</b>/- Received From Seller Signature</p>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-8 "></div>
        <div class="col-lg-1 " *ngIf="isGeneratingbutton">
            <button (click)="navigateTOeditform( )" class="pdf"><i class="fa fa-edit"></i> Edit</button>

        </div>
        <div class="col-lg-3" *ngIf="isGeneratingbutton">
            <button (click)="downloadPDF()" class="pdf"><i class="fa fa-download"></i> Generate PDF</button>
        </div>
    </div>
