import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators, ReactiveFormsModule } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { GeneralserviceService } from '../../generalservice.service';
import Swal from 'sweetalert2';
import { HttpClient } from '@angular/common/http';
@Component({
  selector: 'app-noticeform5',

  templateUrl: './noticeform5.component.html',
  styleUrl: './noticeform5.component.scss'
})
export class Noticeform5Component {

    HomeUrl: string;
    arr: any[] = [];
    loginDet: any;
    otpSent: boolean = false;
    showLogin: boolean = false;
    showLogin1: boolean = true;
    registerAsDonor: boolean = false;
    RegId: any;
    LoginDetails: any;
    RegisterForm: FormGroup;
    OTPtoMobile: string;
    checkNo: number;
    Mobilenum: string;
    mobiledata: any;
    classdata: any;
    classname: any;
    deptList: any;
    deptList1: any;
    // State: any;
    StateId: any;
    DistrictName: any;
    flag: any;
    rolename: any
    Districtdata: any;
    selectedDistrictName: any;
    Statedata: any;
    State: any = [];
    selectedStateName: any;
    selectedstateid: any;
    district: any = [];
    Citydata: any = [];
    Fdata: any;
    aggrementno: any
    isUpdateMode: boolean = false;
    LegalNoticeID: number;
    cardNumber: any;
    Sname: any;
    constructor(
        public router: Router,
        private formBuilder: FormBuilder,
        public generalService: GeneralserviceService,
        public http: HttpClient,
        public activeroute: ActivatedRoute
    ) {
        this.aggrementno = localStorage.getItem("Agreement");
        this.loginDet = JSON.parse(localStorage.getItem("LoginDetails"));
        // this.rolename = localStorage.getItem("clickedName");
        // this.Sname = +this.route.snapshot.paramMap.get('cardNumber');
        //   this.cardNumber = this.activeroute.snapshot.paramMap.get("Form").replace(/\_/g, " ").trim();


        this.http.get('../assets/WebService.json').subscribe((data: any) => {
            this.HomeUrl = data.Webservice;
        });
        debugger
        // this.LegalNoticeID = this.cardNumber;
        this.initializeForm();

        if (!this.loginDet || !this.loginDet[0] || !this.loginDet[0].RegID) {
            this.router.navigate(['/Login']);
            return;
        }
    }


    initializeForm(): void {


        this.RegisterForm = this.formBuilder.group({
            AgreementNo: ['', Validators.required],
            //CompanyName: ['', Validators.required],
          //  CompanyAddress: ['', Validators.required],
            SecondSaleDealerCompanyName: ['', Validators.required],
            SecondSaleDealerCompanyAddress: ['', Validators.required],
            VehicleRegNo: ['', Validators.required],
            SellWithInDays: ['', Validators.required],
            DueDate: ['', Validators.required]
        });

    }


    ngOnInit() {
        

        this.Getforms();
    }
    Getforms() {
        debugger;
        const uploadfile = new FormData();
        uploadfile.append('Param', this.aggrementno);
        var url = "api/FinancierLaw/FA_NoticeForms_basedon_ARGNO";
        this.generalService.Postdata1(url, uploadfile).subscribe(
            data => {
                debugger;
                this.Fdata = data;
               // const auctionedDate = this.formatDate(this.Fdata[0].datedfrom);
                this.RegisterForm.patchValue({
                    AgreementNo: this.Fdata[0].AgreementNo,
                    VehicleRegNo: this.Fdata[0].VehicleRegNo,
                    CompanyName: this.Fdata[0].CompanyName,
                    CompanyAddress: this.Fdata[0].CompanyAddress,
                    SecondSaleDealerCompanyName: this.Fdata[0].SecondSaleDealerCompanyName,
                    SecondSaleDealerCompanyAddress: this.Fdata[0].SecondSaleDealerCompanyAddress,
                    SellWithInDays: this.Fdata[0].SellWithInDays,
                   // datedfrom: auctionedDate,
                    DueDate: this.formatDate(this.Fdata[0].DueDate)

                    // Add other fields as needed based on Fdata
                });
            },
            err => {
                //this.generalService.ShowAlert('ERROR', 'Something went wrong. Please try again later', 'error');
            }
        );
    }
    formatDate(dateString: string): string {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = ('0' + (date.getMonth() + 1)).slice(-2);
        const day = ('0' + date.getDate()).slice(-2);
        return `${year}-${month}-${day}`;
    }


    OnSubmit() {
        throw new Error('Method not implemented.');
    }

    reg() {
        if (!this.LoginDetails || Object.keys(this.LoginDetails).length === 0) {
            this.router.navigate(['/Login']);
            return;
        } else {
            this.router.navigate(['/Home']).then(() => window.location.reload());
        }
    }




    getRegistration(value: any) {
        debugger;

        if (!value.SecondSaleDealerCompanyName || !value.SecondSaleDealerCompanyAddress || !value.SellWithInDays ||
            !value.VehicleRegNo || !value.DueDate) {
            alert("Please fill all the mandatory fields: VehicleRegNo,SaleDealerCompanyName, SaleDealerCompanyAddress,SellWithInDays,LoanDate.");
            return; // Exit the function if validation fails
        }
        this.arr = [];

        var arr = [{
            // Name: value.Name,
          
            NoticeID:6,
            RegID: this.loginDet[0].RegID,
  
            VehicleRegNo: value.VehicleRegNo,         
            SecondSaleDealerCompanyName: value.SecondSaleDealerCompanyName,
            SecondSaleDealerCompanyAddress: value.SecondSaleDealerCompanyAddress,
            SellWithInDays: value.SellWithInDays,
            DueDate: value.DueDate
        }];

        const uploadfile = new FormData();
        uploadfile.append('Param', JSON.stringify(arr));
        uploadfile.append('Flag', '1');

        const url = 'api/FinancierLaw/FA_NoticeFormsCRUD';
        this.generalService.Postdata1(url, uploadfile).subscribe((data: any) => {
            debugger

            if (data && data) {

                alert("Your Form submitted successfully");
                localStorage.setItem('notice5', data);
                this.router.navigate(['/finalnotice5']);


            }
        },

        );
    }
    updateRegistration(value: any) {
        debugger;
        this.arr = [];

        var arr = [{
            NoticeID:6,
            NoticeFormID: this.Fdata[0].NoticeFormID,
            AgreementNo: value.AgreementNo,
            VehicleRegNo: value.VehicleRegNo,
            CompanyName: value.CompanyName,
            CompanyAddress: value.CompanyAddress,
            SecondSaleDealerCompanyName: value.SecondSaleDealerCompanyName,
            SecondSaleDealerCompanyAddress: value.SecondSaleDealerCompanyAddress,
            SellWithInDays: value.SellWithInDays,
            DueDate: value.DueDate,
            RegID: this.loginDet[0].RegID
        }];

        const uploadfile = new FormData();
        uploadfile.append('Param', JSON.stringify(arr));
        uploadfile.append('Flag', '2');

        const url = 'api/FinancierLaw/FA_NoticeFormsCRUD';
        this.generalService.Postdata1(url, uploadfile).subscribe((data: any) => {
            debugger

            if (data && data) {

                alert("Your Form Updated successfully");
                localStorage.setItem('notice5', data);
                this.router.navigate(['/finalnotice5']);
                localStorage.removeItem('Agreement');

            }
        },

        );
    }
    convertToUppercase() {
        const control = this.RegisterForm.get('VehicleRegNo');
        if (control) {
            control.setValue(control.value.toUpperCase(), { emitEvent: false });
        }
    }



}
