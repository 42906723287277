<!--4th Form-->
<div class="ptb-100">
    <div class="container-fluid bg-breadcrumb">
        <div class="container text-center "><br />
            <h6 class="text-white display-3 mb-4 wow fadeInDown" data-wow-delay="0.1s" style="visibility: visible; animation-delay: 0.1s; animation-name: fadeInDown;">
                Surrender Letter
            </h6>
            <ol class="breadcrumb justify-content-center mb-0 wow fadeInDown" data-wow-delay="0.3s" style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInDown;">
                <li class="breadcrumb-item"><a href="index.html">Home</a></li>
                <li class="breadcrumb-item active text-white">Surrender Letter</li>
            </ol>
        </div>
    </div>
    <br /><br /><div style="margin-top:60px"></div>
                <div id="pdf-content" class=" mt-5">
                    <div class="container-fluid pt-3 content">
                        <div class="container">
                            <div class="row no-gap">
                                <div class="col-lg-12">
                                    <h2 class="surr">
                                        <b style="font-size:30px">SURRENDER LETTER FROM THE BORROWER</b>
                                    </h2>
                                    <br />
                                    <br />
                                </div>
                                <div class="col-lg-12 border-top1 border-top2"></div><br />
                                <div class="col-lg-12 col-4">
                                    <p>To<br /><span style="color:red">{{loginDet[0].CompanyName}},<br />{{loginDet[0].CompanyAddress}}</span></p><br />
                                    <p>Dear Sir/ Madam,</p>
                                    <p>
                                        <b>Subject:</b>
                                        Surrender of Vehicle with registration Number
                                        <span style="color:red">{{Fdata[0].VehicleRegNo}}</span> & manufacturer <span style="color:red">{{Fdata[0].Manufacture}}</span> & model <span style="color:red">{{Fdata[0].Model}}</span>.<br />
                                    </p>
                                    <p>
                                        <span style="color:red; margin-left:4rem;">I {{Fdata[0].BorrowerName}}</span> Son/ Daughter of <span style="color:red">{{Fdata[0].FatherName}}</span>, residing at
                                        <span style="color:red">{{Fdata[0].BarrowerAddress}},</span> hereby submit the following
                                        statement:
                                    </p>
                                </div>
                            </div><br />
                            <div class="main-content">
                                <p class="para mt-3">
                                    I acknowledge that I have acquired a registered vehicle No.
                                    <span style="color:red;">{{Fdata[0].VehicleRegNo}}</span> through your finance scheme. However, due to
                                    unforeseen circumstances, I express my inability to meet the
                                    installment payments due. Therefore, I am voluntarily
                                    surrendering the aforementioned vehicle to your finance
                                    company. I affirm that this decision is made willingly and
                                    without any external pressure. I acknowledge the rights vested
                                    in the finance company as outlined in the composite loan
                                    agreement for loan and guarantee, which I have violated by
                                    defaulting on the scheduled repayments for the vehicle loan.
                                </p>
                                <p class="para mt-3">
                                    Hence, I kindly request your cooperation in accepting the
                                    surrendered vehicle.
                                </p>
                                <p class="para mt-3">
                                    Thank you for your understanding and cooperation.
                                </p>
                            </div><br />
                            <div class="row">
                                <div class="col-lg-6">
                                    <div class="text">
                                        <p>Yours sincerely</p><br />
                                        <p>Date : <span style="color:red">{{todayDate}}</span></p>
                                    </div>
                                </div>
                              
                                <div class="col-lg-3"></div>
                                <div class="col-lg-3 text-left">
                                    <div class="text-center">
                                        <p>Name</p><br /><br />
                                        <p>Signature</p>
                                    </div>
                                </div>

                            </div>

                        </div>

                    </div><br /> <br />
                    <div class="container">
                        <div class="col-lg-12"></div>
                    </div>

                </div>
</div>
<div class="row">
    <div class="col-lg-8 ">
    </div>
    <div class="col-lg-1 ">
        <button (click)="navigateTOeditform(Fdata[0].RefNo )" class="pdf"><i class="fa fa-edit"></i> Edit  </button>
    </div>
    <div class="col-lg-3">
        <button (click)="downloadPDF()" class="pdf"><i class="fa fa-download"></i> Generate PDF  </button>
    </div>
</div>
