<div class="ptb-100">
    <div class="container-fluid bg-breadcrumb">
        <div class="container text-center ">
            <h6 class="text-white display-3 mb-4 wow fadeInDown" data-wow-delay="0.1s" style="visibility: visible; animation-delay: 0.1s; animation-name: fadeInDown;">
                Legal Form
            </h6>
            <ol class="breadcrumb justify-content-center mb-0 wow fadeInDown" data-wow-delay="0.3s" style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInDown;">
                <li class="breadcrumb-item"><a href="index.html">Home</a></li>
                <li class="breadcrumb-item active text-white"> Legal Form</li>
            </ol>
        </div>
    </div>
    <div class="container" style="margin-top: 2%;margin-bottom: 15px;">
        <div class="row">
            <div class="col-12">
                <fieldset>
                    <form [formGroup]="RegisterForm">
                        <div class="row p-5">
                            <div class="col-lg-4">
                                <div class="form-group">
                                    <label for="Mobile">Borrower Name</label>
                                    <input type="text" formControlName="BorrowerName" placeholder="Enter Your Borrower Name" inputmode="numeric" maxlength="50" required>
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <div class="form-group">
                                    <label for="BarrowerAddress">Borrower Address</label>
                                    <input type="text" formControlName="BarrowerAddress" placeholder="Enter Your Borrower Address" id="BarrowerAddress" required>
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <div class="form-group">
                                    <label for="BarrowerMobile">Barrower Mobile</label>
                                    <input type="text"
                                           formControlName="BarrowerMobile"
                                           placeholder="Enter Your Barrower Mobile"
                                           id="BarrowerAddress"
                                           maxlength="10"
                                           pattern="[0-9]*"
                                           required
                                           >
                                </div>
                            </div>


                            <div class="col-lg-4">
                                <div class="form-group">
                                    <label for="VehicleRegNo">Sold date<span style="color:red">*</span></label>
                                    <input type="date" formControlName="DueDate" placeholder="Enter Your Sold date" id="BorrowerName" required >
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <div class="form-group">

                                    <label for="Manufacture">Appear Date</label>
                                    <input type="date" formControlName="SeizedDate" placeholder="Enter Your Appear Date" id="BarrowerAddress" required>
                                </div>
                            </div>





                            <div class="form-group">
                                <!-- If AgreementNo is null, undefined, or empty, show Submit button -->
                                <button *ngIf="notice7===null" class="btn" (click)="getRegistration(RegisterForm.value)">
                                    <b>Preview</b>
                                </button>

                                <!-- If AgreementNo has a value, show Update button -->
                                <button *ngIf=" notice7!==null" class="btn" (click)="updateRegistration(RegisterForm.value)">
                                    <b>Update</b>
                                </button>
                            </div>

                        </div>
                    </form>
                </fieldset>
            </div>
        </div>
    </div>
</div>
