import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators, ReactiveFormsModule } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { GeneralserviceService } from '../../generalservice.service';
import Swal from 'sweetalert2';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
@Component({
    selector: 'app-noticeform1',

    templateUrl: './noticeform1.component.html',
    styleUrl: './noticeform1.component.scss'
})
export class Noticeform1Component {


    HomeUrl: string;
    arr: any[] = [];
    loginDet: any;
    otpSent: boolean = false;
    showLogin: boolean = false;
    showLogin1: boolean = true;
    registerAsDonor: boolean = false;
    RegId: any;
    LoginDetails: any;
    RegisterForm: FormGroup;
    OTPtoMobile: string;
    checkNo: number;
    Mobilenum: string;
    mobiledata: any;
    classdata: any;
    classname: any;
    deptList: any;
    deptList1: any;
    // State: any;
    StateId: any;
    DistrictName: any;
    flag: any;
    rolename: any
    Districtdata: any;
    selectedDistrictName: any;
    Statedata: any;
    State: any = [];
    selectedStateName: any;
    selectedstateid: any;
    district: any = [];
    Citydata: any = [];
    Fdata: any;
    aggrementNo: any
    isUpdateMode: boolean = false;
    LegalNoticeID: number;
    cardNumber: any;
    Sname: any; aggrementno:any
    constructor(
        public router: Router,
        private formBuilder: FormBuilder,
        public generalService: GeneralserviceService,
        public http: HttpClient,
        public activeroute: ActivatedRoute, private route: ActivatedRoute
    ) {
        this.loginDet = JSON.parse(localStorage.getItem("LoginDetails"));
        // this.rolename = localStorage.getItem("clickedName");
        // this.Sname = +this.route.snapshot.paramMap.get('cardNumber');
        //   this.cardNumber = this.activeroute.snapshot.paramMap.get("Form").replace(/\_/g, " ").trim();
         this.aggrementno = localStorage.getItem("AgreementNo");

        this.http.get('../assets/WebService.json').subscribe((data: any) => {
            this.HomeUrl = data.Webservice;
        });
        debugger
        this.LegalNoticeID = this.cardNumber;
        this.initializeForm();

        if (!this.loginDet || !this.loginDet[0] || !this.loginDet[0].RegID) {
            this.router.navigate(['/Login']);
            return;
        }
    }


    initializeForm(): void {


        this.RegisterForm = this.formBuilder.group({
           // AgreementNo: ['', Validators.required],
            BorrowerName: ['', Validators.required],
            BarrowerAddress: ['', Validators.required],
            SuretyName: ['', Validators.required],
            SuretyAddress: ['', Validators.required],
            Financecompanyname: ['', Validators.required],
            NoticeIssuedDate: ['', Validators.required],
            DueAmount: ['', Validators.required],
            //totaldueAmount: ['', Validators.required],
            LoanAmount: ['', Validators.required],
            VehicleRegNo: ['', Validators.required],
            SuretyMobile: ['', Validators.required],
            BarrowerMobile: ['', Validators.required]
        });

    }


    ngOnInit() {
        this.getstates();
        this.activeroute.queryParams.subscribe(params => {
            this.aggrementNo = params['AggrementNo'];
            console.log(this.aggrementNo); // Use the AggrementNo as needed
        });
        this.Getforms();
    }
    Getforms() {
        debugger;
        const uploadfile = new FormData();
        uploadfile.append('Param', this.aggrementno);
        var url = "api/FinancierLaw/FA_NoticeForms_basedon_ARGNO";
        this.generalService.Postdata1(url, uploadfile).subscribe(
            data => {
                debugger;
                this.Fdata = data;
               // const auctionedDate = this.formatDate(this.Fdata[0].NoticeIssuedDate);
                this.RegisterForm.patchValue({

                    AgreementNo: this.Fdata[0].AgreementNo,
                    BorrowerName: this.Fdata[0].BorrowerName,
                    BarrowerAddress: this.Fdata[0].BarrowerAddress,
                    SuretyName: this.Fdata[0].SuretyName,
                    SuretyAddress: this.Fdata[0].SuretyAddress,
                    Financecompanyname: this.Fdata[0].Financecompanyname,
                    //noticeissuedDate: this.Fdata[0].noticeissuedDate,
                    //NoticeIssuedDate: auctionedDate,
                    DueAmount: this.Fdata[0].DueAmount,
                    LoanAmount: this.Fdata[0].LoanAmount,
                    SuretyMobile: this.Fdata[0].SuretyMobile,
                    BarrowerMobile: this.Fdata[0].BarrowerMobile,
                    VehicleRegNo: this.Fdata[0].VehicleRegNo,
                    NoticeIssuedDate: this.formatDate(this.Fdata[0].NoticeIssuedDate),
                    // RegID: this.loginDet[0].RegID
                    // Add other fields as needed based on Fdata
                });
            },
            err => {
                //this.generalService.ShowAlert('ERROR', 'Something went wrong. Please try again later', 'error');
            }
        );
    }
    formatDate(dateString: string): string {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = ('0' + (date.getMonth() + 1)).slice(-2);
        const day = ('0' + date.getDate()).slice(-2);
        return `${year}-${month}-${day}`;
    }

    convertToUppercase() {
        const control = this.RegisterForm.get('VehicleRegNo');
        if (control) {
            control.setValue(control.value.toUpperCase(), { emitEvent: false });
        }
    }
    OnSubmit() {
        throw new Error('Method not implemented.');
    }

    reg() {
        if (!this.LoginDetails || Object.keys(this.LoginDetails).length === 0) {
            this.router.navigate(['/Login']);
            return;
        } else {
            this.router.navigate(['/Home']).then(() => window.location.reload());
        }
    }




    getRegistration(value: any) {
        debugger;
        this.arr = [];

        var arr = [{
            // Name: value.Name,
          //  AgreementNo: value.AgreementNo,
            BorrowerName: value.BorrowerName,
            BarrowerAddress: value.BarrowerAddress,
            SuretyName: value.SuretyName,
            SuretyAddress: value.SuretyAddress,

            NoticeIssuedDate: value.NoticeIssuedDate,
            DueAmount: value.DueAmount,
            NoticeID:2,
            VehicleRegNo: value.VehicleRegNo,
            BarrowerMobile: value.BarrowerMobile,
            SuretyMobile: value.SuretyMobile,
            RegID: this.loginDet[0].RegID
        }];

        const uploadfile = new FormData();
        uploadfile.append('Param', JSON.stringify(arr));
        uploadfile.append('Flag', '1');

        const url = 'api/FinancierLaw/FA_NoticeFormsCRUD';
        this.generalService.Postdata1(url, uploadfile).subscribe((data: any) => {
            debugger

            //if (data == 'SUCCESS') {

            //    alert("Your Form submitted successfully");
            //    localStorage.setItem('AgreementNo', value.AgreementNo);
            //    //  this.router.navigate(['/FinacialformPDF']);
            //    this.router.navigate(['/finalnotice']);


            //}
            if (data && data) {

                alert("Your Form submitted successfully");
                localStorage.setItem('AgreementNo', data);
                this.router.navigate(['/finalnotice']);


            }


        },

        );
    }
    updateRegistration(value: any) {
        debugger;
        this.arr = [];

        var arr = [{
            NoticeFormID: this.Fdata[0].NoticeFormID,
            AgreementNo: value.AgreementNo,
            BorrowerName: value.BorrowerName,
            BarrowerAddress: value.BarrowerAddress,
            SuretyName: value.SuretyName,
            SuretyAddress: value.SuretyAddress,

            NoticeIssuedDate: value.NoticeIssuedDate,
            DueAmount: value.DueAmount,
            NoticeID:2,
            VehicleRegNo: value.VehicleRegNo,
            SuretyMobile: value.SuretyMobile,
            BarrowerMobile: value.BarrowerMobile,
            RegID: this.loginDet[0].RegID
        }];

        const uploadfile = new FormData();
        uploadfile.append('Param', JSON.stringify(arr));
        uploadfile.append('Flag', '2');

        const url = 'api/FinancierLaw/FA_NoticeFormsCRUD';
        this.generalService.Postdata1(url, uploadfile).subscribe((data: any) => {
            debugger

            if (data == 'SUCCESS') {

                alert("Your Form Updated successfully");
                //localStorage.setItem('AgreementNo', value.AgreementNo);
                this.router.navigate(['/finalnotice']);


            }
        },

        );
    }

    // Method to fetch states from the server
    getstates() {
        debugger;
        var url = "api/FinancierLaw/FA_GetStates";
        this.generalService.Getdata(url).then(
            data => {
                debugger;
                this.Statedata = data;  // Store the fetched state data
            },
            err => {
                this.generalService.ShowAlert('ERROR', 'Something went wrong. Please try again later', 'error');
            }
        );
    }

    // Method called when a state is selected in the dropdown
    onStateSelection(value: string) {
        debugger;
        this.StateId = value;  // Store the selected state ID
        this.Getdistricts();  // Fetch districts based on the selected state
    }

    // Method to fetch districts based on the selected state ID
    Getdistricts() {
        debugger;
        if (!this.StateId) {
            this.generalService.ShowAlert('ERROR', 'State ID is missing. Please select a state.', 'error');
            return;
        }

        var UploadFile = new FormData();
        UploadFile.append("StateId", this.StateId);  // Append the state ID to the form data

        var url = "api/FinancierLaw/FA_GetDistricts";
        this.generalService.Postdata1(url, UploadFile).subscribe(
            data => {
                debugger;
                this.DistrictName = data;  // Store the fetched district data
                if (!this.DistrictName || this.DistrictName.length === 0) {
                    this.generalService.ShowAlert('ERROR', 'No districts found for the selected state.', 'error');
                }
            },
            err => {
                this.generalService.ShowAlert('ERROR', 'Something went wrong. Please try again later', 'error');
            }
        );
    }

    // Method called when a district is selected in the dropdown
    onDistrictSelection(value: string) {
        debugger;
        this.selectedDistrictName = value;  // Store the selected district name
    }

    validateMobileInput(event: KeyboardEvent): void {
        const charCode = event.which ? event.which : event.keyCode;
        // Only allow numbers (0-9)
        if (charCode < 48 || charCode > 57) {
            event.preventDefault();
        }
    }

}
