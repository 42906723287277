<!--<div class="pt-100 bgblue">
    <br />
    <div class="container mt-5">
        <div class="col-lg-12">

            <div class="row">
                <div class="col-lg-3">-->
<!--<a href="/Abscondingform" class="btn btn-primary" title="Add New Absconding Form">
    + Add
</a>-->
<!--</div>

<div class="col-lg-3"></div>-->
<!--<div class="col-lg-6">

    <div class="input-wrapper">
        <i class="fa fa-search search-icon"></i>  Search icon at the start

        <input [(ngModel)]="seracingdata"
               (input)="searchdata()"
               class="searchh"
               style="width:100%;"
               placeholder="Search" />

        <div *ngIf="seracingdata"
             class="close-btn"
             (click)="clearSearch()">X</div>
    </div>

</div>-->
<!--</div>

<div style="overflow-x: auto;">
    <table border="1" cellpadding="10" cellspacing="0" style="width: 200%; min-width:700px; ">
        <thead>
            <tr style="border-bottom:none;">-->
<!--<th class="custom_border">Action</th>-->
<!--<th class="custom_border">Borrower Name</th>
<th class="custom_border">Borrower Father Name</th>
<th class="custom_border">Borrower Mobile Number</th>
<th class="custom_border">Borrower Address</th>
<th class="custom_border">
    Vehicle Number
    <div style="margin-top: 5px;">
        <select id="financerFilter" [(ngModel)]="selectedVehicle" (change)="filterData8()">
            <option value="">All</option>
            <option *ngFor="let financer of uniqueVehicleNumber" [value]="financer">{{financer}}</option>
        </select>
    </div>
</th>
<th class="custom_border">Vehicle Engine Number</th>

<th class="custom_border">Vehicle Chassis Number</th>
<th class="custom_border">
    Financer Name-->
<!-- Dropdown filter for Financer Name -->
<!--<div style="margin-top: 5px;">
                <select id="financerFilter" [(ngModel)]="selectedFinancerName" (change)="filterData7()">
                    <option value="">All</option>
                    <option *ngFor="let financer of uniqueFinancerNames" [value]="financer">{{financer}}</option>
                </select>
            </div>
        </th>
        <th class="custom_border">Prize Money</th>
    </tr>
</thead>
<tbody>
    <tr *ngFor="let item of filteredDataResults4">-->
<!--<td> <i class="fas fa-trash" (click)="deleteItem(item.AVID)" style="color: red;"></i></td>-->
<!--<td>{{item.BorrowerName}}</td>
                            <td>{{item.BorrowerFatherName}}</td>
                            <td>{{item.BorrowerMobileNumber}}</td>
                            <td>{{item.BorrowerAddress}}</td>
                            <td>{{item.VehicleNumber}}</td>
                            <td>{{item.VehicleEngineNumber}}</td>
                            <td>{{item.VehicleChassisNumber}}</td>
                            <td>{{item.FinancerName}}</td>
                            <td>{{item.PrizeMoney}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>


        </div>
    </div>
</div>-->
<!--<div class="pt-100 bgblue">
    <br />
    <div class="container mt-5 box-container">
        <div class="col-lg-12">

            <div class="row">
                <div class="col-lg-3">-->
<!-- Uncomment if you need the add button -->
<!--<a href="/Abscondingform" class="btn btn-primary" title="Add New Absconding Form"> + Add </a>-->
<!--</div>

                <div class="col-lg-3"></div>
            </div>

            <div class="box-content" style="overflow-x: auto;">
                <table border="1" cellpadding="10" cellspacing="0" style="width: 200%; min-width:700px;">
                    <thead>
                        <tr style="border-bottom: none;">
                            <th class="custom_border">Borrower Name</th>
                            <th class="custom_border">Borrower Father Name</th>
                            <th class="custom_border">Borrower Mobile Number</th>
                            <th class="custom_border">Borrower Address</th>
                            <th class="custom_border">
                                Vehicle Number
                                <div style="margin-top: 5px;">
                                    <select id="vehicleFilter" [(ngModel)]="selectedVehicle" (change)="filterData8()">
                                        <option value="">All</option>
                                        <option *ngFor="let financer of uniqueVehicleNumber" [value]="financer">{{financer}}</option>
                                    </select>
                                </div>
                            </th>
                            <th class="custom_border">Vehicle Engine Number</th>
                            <th class="custom_border">Vehicle Chassis Number</th>
                            <th class="custom_border">
                                Financer Name
                                <div style="margin-top: 5px;">
                                    <select id="financerFilter" [(ngModel)]="selectedFinancerName" (change)="filterData7()">
                                        <option value="">All</option>
                                        <option *ngFor="let financer of uniqueFinancerNames" [value]="financer">{{financer}}</option>
                                    </select>
                                </div>
                            </th>
                            <th class="custom_border">Prize Money</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of filteredDataResults4">
                            <td>{{item.BorrowerName}}</td>
                            <td>{{item.BorrowerFatherName}}</td>
                            <td>{{item.BorrowerMobileNumber}}</td>
                            <td>{{item.BorrowerAddress}}</td>
                            <td>{{item.VehicleNumber}}</td>
                            <td>{{item.VehicleEngineNumber}}</td>
                            <td>{{item.VehicleChassisNumber}}</td>
                            <td>{{item.FinancerName}}</td>
                            <td>{{item.PrizeMoney}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>-->


<div class="pt-100 bgblue">
    <br />
    <div class="container mt-5 box-container">
        <div class="col-lg-12">
            <div class="row">
                <div class="col-lg-3">
                    <!-- Uncomment if you need the add button -->
                    <!--<a href="/Abscondingform" class="btn btn-primary" title="Add New Absconding Form"> + Add </a>-->
                </div>

                <div class="col-lg-3"></div>
            </div>

            <div class="box-content" style="overflow-x: auto;">
                <table border="1" cellpadding="10" cellspacing="0" style="width: 200%; min-width:700px;">
                    <thead>
                        <tr style="border-bottom: none;">
                            <th class="custom_border">Borrower Name</th>
                            <th class="custom_border">Borrower Father Name</th>
                            <th class="custom_border">Borrower Mobile Number</th>
                            <th class="custom_border">Borrower Address</th>
                            <th class="custom_border">
                                Vehicle Number
                                <div style="margin-top: 5px;">
                                    <select id="vehicleFilter" [(ngModel)]="selectedVehicle" (change)="filterData8()">
                                        <option value="">All</option>
                                        <option *ngFor="let financer of uniqueVehicleNumber" [value]="financer">{{financer}}</option>
                                    </select>
                                </div>
                            </th>
                            <th class="custom_border">Vehicle Engine Number</th>
                            <th class="custom_border">Vehicle Chassis Number</th>
                            <th class="custom_border">
                                Financer Name
                                <div style="margin-top: 5px;">
                                    <select id="financerFilter" [(ngModel)]="selectedFinancerName" (change)="filterData7()">
                                        <option value="">All</option>
                                        <option *ngFor="let financer of uniqueFinancerNames" [value]="financer">{{financer}}</option>
                                    </select>
                                </div>
                            </th>
                            <th class="custom_border">Prize Money</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of filteredDataResults4">
                            <td>{{item.BorrowerName}}</td>
                            <td>{{item.BorrowerFatherName}}</td>
                            <td>{{item.BorrowerMobileNumber}}</td>
                            <td>{{item.BorrowerAddress}}</td>
                            <td>{{item.VehicleNumber}}</td>
                            <td>{{item.VehicleEngineNumber}}</td>
                            <td>{{item.VehicleChassisNumber}}</td>
                            <td>{{item.FinancerName}}</td>
                            <td>{{item.PrizeMoney}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>
