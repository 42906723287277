import { Component } from '@angular/core';

@Component({
  selector: 'app-buyer',

  templateUrl: './buyer.component.html',
  styleUrl: './buyer.component.scss'
})
export class BuyerComponent {

}


