<div class="ptb-100">
    <div class="container-fluid bg-breadcrumb">
        <div class="container text-center ">
            <h6 class="text-white display-3 mb-4 wow fadeInDown" data-wow-delay="0.1s" style="visibility: visible; animation-delay: 0.1s; animation-name: fadeInDown;">
                Seller Form
            </h6>
            <ol class="breadcrumb justify-content-center mb-0 wow fadeInDown" data-wow-delay="0.3s" style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInDown;">
                <li class="breadcrumb-item"><a href="index.html">Home</a></li>
                <li class="breadcrumb-item active text-white">Seller Form</li>
            </ol>
        </div>
    </div>

    <br />
       
    <div class="container">
        <div class="stepper-container">
            <div class="step" [ngClass]="{ 'active': currentStep >= 1 }">
                <div class="circle" [ngClass]="{ 'completed': currentStep > 1 }">
                    <i *ngIf="currentStep > 1" class="fa fa-check"></i>
                </div>
                <p>Seller Details</p>
            </div>
            <div class="step" [ngClass]="{ 'active': currentStep >= 2 }">
                <div class="circle" [ngClass]="{ 'completed': currentStep > 2 }">
                    <i *ngIf="currentStep > 2" class="fa fa-check"></i>
                </div>
                <p>Buyer Details</p>
            </div>
            <div class="step" [ngClass]="{ 'active': currentStep >= 3 }">
                <div class="circle" [ngClass]="{ 'completed': currentStep > 3 }">
                    <i *ngIf="currentStep > 3" class="fa fa-check"></i>
                </div>
                <p>Vehicle Details</p>
            </div>
            <div class="step" [ngClass]="{ 'active': currentStep >= 4 }">
                <div class="circle" [ngClass]="{ 'completed': currentStep > 3 }">
                    <i *ngIf="currentStep > 4" class="fa fa-check"></i>
                </div>
                <p>Signatures </p>
            </div>
        </div>
    </div>
    <div class="container" style="margin-top: 2%;margin-bottom: 15px;">

        <div class="row">
            <div class="col-12">
                <fieldset *ngIf="currentStep === 1">
                    <legend>Seller Details</legend>
                    <form [formGroup]="sellForm">
                        <div class="row p-5">
                            <div class="col-lg-4 mt-3">
                                <div class="form-group">
                                    <label for="Mobile">Seller Name</label>
                                    <input type="text" formControlName="SellerName" placeholder="Enter Seller Name" maxlength="50" required>
                                </div>
                            </div>
                            <div class="col-lg-4  mt-3">
                                <div class="form-group">
                                    <label for="VehicleRegNo">Seller Father Name</label>
                                    <input type="text"
                                           formControlName="SellerFatherName"
                                           placeholder="Enter Seller Father Name"
                                           id="VehicleRegNo"
                                           required
                                           (keyup)="convertToUppercase()">
                                </div>
                            </div>

                            <div class="col-lg-4  mt-3">
                                <div class="form-group">
                                    <label for="BorrowerName">Seller Age</label>
                                    <input type="text" formControlName="SellerAge" placeholder="Enter Seller Age" id="BorrowerName" required>
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <div class="form-group">
                                    <label for="BarrowerAddress">Seller Occupation</label>
                                    <input type="text" formControlName="SellerOccupation" placeholder="Enter Seller Occupation" id="BarrowerAddress" required>
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <div class="form-group">
                                    <label for="BarrowerMobile">Seller HNo</label>
                                    <input type="text" formControlName="SellerHNo" placeholder="Enter Seller House No" id="BarrowerAddress" required>
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <div class="form-group">
                                    <label for="BorrowerName">Seller Street*</label>
                                    <input type="text" formControlName="SellerStreet" placeholder="Enter Seller Street" id="SuretyName" required>
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <div class="form-group">
                                    <label for="BarrowerAddress">Seller Colony</label>
                                    <input type="text" formControlName="SellerColony" placeholder="Enter Seller Colony" id="SuretyAddress" required>
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <div class="form-group">
                                    <label for="SuretyMobile">Seller Mandal</label>
                                    <input type="text" formControlName="SellerMandal" placeholder="Enter Seller Mandal" id="SuretyAddress" required>
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <div class="form-group">
                                    <label for="LoanAmount">Seller State</label>
                                    <input type="text" formControlName="SellerState" placeholder="Enter Seller State" id="LoanAmount" required>
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <div class="form-group">
                                    <label for="DueAmount">Seller Pincode</label>
                                    <input type="text" formControlName="SellerPincode" placeholder="Enter Seller Pincode" id="DueAmount" required>
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <div class="form-group">
                                    <label for="DueDate"> Seller Mobile</label>
                                    <input type="text" formControlName="SellerMobile" placeholder="Enter Seller Mobile"  maxlength="10"
                                           pattern="[0-9]*" id="AuctionedDate" required>
                                </div>
                            </div>


                        </div>
                        <div class="form-group mt-4">
                            <!--<button type="button" class="btn btn-secondary" (click)="goToPrevious()">Previous</button>-->
                            <button type="button" class="btn btn-primary" (click)="goToNext()">Next</button>
                        </div>
                    </form>
                </fieldset>
                <fieldset *ngIf="currentStep === 2">
                    <legend>Buyer Details</legend>
                    <form [formGroup]="buyForm">

                        <div class="row p-5">
                            <div class="col-lg-4 mt-3">
                                <div class="form-group">
                                    <label for="BuyerName">Buyer Name*</label>
                                    <input type="text" formControlName="BuyerName" placeholder="Enter Buyer Name" maxlength="50" required>
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <div class="form-group">
                                    <label for="BuyerFatherName">Buyer Father Name*</label>
                                    <input type="text" formControlName="BuyerFatherName" placeholder="Enter Buyer Father's Name" required>
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <div class="form-group">
                                    <label for="BuyerAge">Buyer Age*</label>
                                    <input type="text" formControlName="BuyerAge" placeholder="Enter Buyer Age" required>
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <div class="form-group">
                                    <label for="BuyerOccupation">Buyer Occupation</label>
                                    <input type="text" formControlName="BuyerOccupation" placeholder="Enter Buyer Occupation" required>
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <div class="form-group">
                                    <label for="BuyerHNo">Buyer HNo</label>
                                    <input type="text" formControlName="BuyerHNo" placeholder="Enter Buyer House Number" required>
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <div class="form-group">
                                    <label for="BuyerStreet">Buyer Street*</label>
                                    <input type="text" formControlName="BuyerStreet" placeholder="Enter Buyer Street" required>
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <div class="form-group">
                                    <label for="BuyerColony">Buyer Colony</label>
                                    <input type="text" formControlName="BuyerColony" placeholder="Enter Buyer Colony" required>
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <div class="form-group">
                                    <label for="BuyerMandal">Buyer Mandal</label>
                                    <input type="text" formControlName="BuyerMandal" placeholder="Enter Buyer Mandal" required>
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <div class="form-group">
                                    <label for="BuyerState">Buyer State</label>
                                    <input type="text" formControlName="BuyerState" placeholder="Enter Buyer State" required>
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <div class="form-group">
                                    <label for="BuyerPincode">Buyer Pincode</label>
                                    <input type="text" formControlName="BuyerPincode" placeholder="Enter Buyer Pincode" required>
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <div class="form-group">
                                    <label for="BuyerMobile">Buyer Mobile</label>
                                    <input type="text" formControlName="BuyerMobile"  maxlength="10"
                                           pattern="[0-9]*" placeholder="Enter Buyer Mobile" required>
                                </div>
                            </div>

                            <div class="form-group">
                                <!-- If AgreementNo is null, undefined, or empty, show Submit button -->
                                <div class="form-group mt-4">
                                    <button type="button" class="btn btn-secondary" (click)="goToPrevious()">Previous</button>
                                    <button type="button" class="btn btn-primary" (click)="goToNext()">Next</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </fieldset>
                <fieldset style="margin-top: 20px;" *ngIf="currentStep === 3">
                    <legend>Vehicle and Transaction Details</legend>
                    <form [formGroup]="vehicleForm">
                        <div class="row p-5">
                            <!-- Vehicle and Transaction Details Fields -->
                            <div class="col-lg-4">
                                <div class="form-group">
                                    <label for="VehicleRegistrationNo">Vehicle Registration No*</label>
                                    <input type="text" formControlName="VehicleRegistrationNo" placeholder="Enter Vehicle Registration No" required>
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <div class="form-group">
                                    <label for="VehicleChassisNo">Vehicle Chassis No*</label>
                                    <input type="text" formControlName="VehicleChassisNo" placeholder="Enter Vehicle Chassis No" required>
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <div class="form-group">
                                    <label for="VehicleEngineNo">Vehicle Engine No*</label>
                                    <input type="text" formControlName="VehicleEngineNo" placeholder="Enter Vehicle Engine No" required>
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <div class="form-group">
                                    <label for="VehicleType">Vehicle Type*</label>
                                    <select formControlName="VehicleType" required style="    width: 100%;">
                                        <option value="" disabled selected>Select Vehicle Type</option>
                                        <option value="Car">Car</option>
                                        <option value="Bike">Bike</option>
                                        <option value="Auto">Auto</option>
                                        <option value="Other">Other</option>
                                    </select>
                                </div>
                            </div>

                            <!--<div class="col-lg-4">
        <div class="form-group">
            <label for="TransportType">Transport Type</label>
            <input type="text" formControlName="TransportType" placeholder="Enter Transport Type" required>
        </div>
    </div>-->
                            <div class="col-lg-4">
                                <div class="form-group">
                                    <label for="VehicleModel">Vehicle Model*</label>
                                    <input type="text" formControlName="VehicleModel" placeholder="Enter Vehicle Model" required>
                                </div>
                            </div>
                            <!--<div class="col-lg-4">
                                <div class="form-group">
                                    <label for="VehicleFuelType">Vehicle Fuel Type*</label>
                                    <input type="text" formControlName="VehicleFuelType" placeholder="Enter Vehicle Fuel Type" required>
                                </div>
                            </div>-->
                            <div class="col-lg-4">
                                <div class="form-group">
                                    <label for="VehicleFuelType">Vehicle Fuel Type*</label>
                                    <select formControlName="VehicleFuelType" required style="    width: 100%;">
                                        <option value="" disabled selected>Select Vehicle Fuel Type </option>
                                        <option value="Petrol">Petrol</option>
                                        <option value="Diesel">Diesel</option>
                                        <option value="LPG">LPG</option>
                                        <option value="CNG">CNG</option>
                                        <option value="EV">EV</option>
                                        <option value="Other">Other</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <div class="form-group">
                                    <label for="FitnessValidity">Fitness Validity*</label>
                                    <input type="date" formControlName="FitnessValidity" placeholder="Enter Fitness Validity" required>
                                </div>
                            </div><div class="col-lg-4">
                                <div class="form-group">
                                    <label for="PermitNumber">Permit Number*</label>
                                    <input type="text" formControlName="PermitNumber" placeholder="Enter Permit Number" required>
                                </div>
                            </div><div class="col-lg-4">
                                <div class="form-group">
                                    <label for="PermitValidity">Permit Validity*</label>
                                    <input type="date" formControlName="PermitValidity" placeholder="Enter Permit Validity" required>
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <div class="form-group">
                                    <label for="MeterSealValidity">Meter Seal Validity*</label>
                                    <input type="date" formControlName="MeterSealValidity" placeholder="Enter MeterSealValidity" required>
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <div class="form-group">
                                    <label for="CNGLPGValidity">CNG/LPG Validity*</label>
                                    <input type="date" formControlName="CNGLPGValidity" placeholder="Enter CNG/LPG Validity" required>
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <div class="form-group">
                                    <label for="EVBatteryValidity">EVBattery Validity*</label>
                                    <input type="date" formControlName="EVBatteryValidity" placeholder="Enter EVBattery Validity" required>
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <div class="form-group">
                                    <label for="TaxValidity">Tax Validity*</label>
                                    <input type="date" formControlName="TaxValidity" placeholder="Enter Tax Validity" required>
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <div class="form-group">
                                    <label for="RCValidity">RC Validity*</label>
                                    <input type="date" formControlName="RCValidity" placeholder="Enter RC Validity" required>
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <div class="form-group">
                                    <label for="PaymentDueDate">PaymentDueDate*</label>
                                    <input type="date" formControlName="PaymentDueDate" placeholder="Enter PaymentDueDate" required>
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <div class="form-group">
                                    <label for="VehiclePrice">Vehicle Price*</label>
                                    <input type="text" formControlName="VehiclePrice" placeholder="Enter Vehicle Price" required>
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <div class="form-group">
                                    <label for="PaidAmount">Paid Amount*</label>
                                    <input type="text" formControlName="PaidAmount" placeholder="Enter PaidAmount" required>
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <div class="form-group">
                                    <label for="RemainingAmount">Remaining Amount*</label>
                                    <input type="text" formControlName="RemainingAmount" placeholder="Enter Remaining Amount" required>
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <div class="form-group">
                                    <label for="FinancierName">Financier Name*</label>
                                    <input type="text" formControlName="FinancierName" placeholder="Enter Financier Name" required>
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <div class="form-group">
                                    <label for="FinancierAddress">Financier Address*</label>
                                    <input type="text" formControlName="FinancierAddress" placeholder="Enter Financier Address" required>
                                </div>
                            </div>

                            <div class="form-group">
                                <button type="button" class="btn btn-secondary" (click)="goToPrevious()">Previous</button>
                                <button type="button" class="btn btn-primary" (click)="goToNext()">Next</button>

                            </div>
                        </div>
                    </form>
                </fieldset>
                <fieldset style="margin-top: 20px;" *ngIf="currentStep === 4">
                    <legend>Photos Details</legend>
                    <form [formGroup]="signForm">
                        <div class="row p-5">

                            <!-- FitnessValidity, PermitNumber, PermitValidity, etc. -->
                            <!--<div class="col-lg-4">
        <div class="form-group">
            <label for="SellerSignature">Seller Signature</label>
            <input type="file" (click)="autoUpload()" formControlName="SellerSignature" required>
        </div>
    </div>-->
                            <div class="col-lg-6">
                                <p><b>Seller Signature<span style="color:red">*</span></b></p>
                                <input type="file" (change)="detectFiles($event)"
                                       #input accept="application/pdf,image/*" class="m-img-upload-btn" style="padding: 5px; background-color: purple; color: white; width: 100%; border: none; border-radius: 0;" />

                                <div class="card1 images-container">
                                    <div class="row">
                                        <div *ngFor="let image of Exteriorimg; let i=index;" class="m-image-wrapper col-lg-3">
                                            <i class="fa fa-times m-delete-img" (click)="removeImage1(i)"></i>
                                            <img [attr.src]="image" *ngIf="image" style="cursor:pointer;" class="img-fluid1">
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-6">
                                <p><b>Buyer Signature<span style="color:red">*</span></b></p>
                                <input type="file" (change)="detectFiles1($event)"
                                       #input accept="application/pdf,image/*" class="m-img-upload-btn" style="padding: 5px; background-color: purple; color: white; width: 100%; border: none; border-radius: 0;" />

                                <div class="card1 images-container">
                                    <div class="row">
                                        <div *ngFor="let image of BuyerSignature; let i=index;" class="m-image-wrapper col-lg-3">
                                            <i class="fa fa-times m-delete-img" (click)="removeImage(i)"></i>
                                            <img [attr.src]="image" *ngIf="image" style="cursor:pointer;" class="img-fluid1">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <p><b>Witness Signature<span style="color:red">*</span></b></p>
                                <input type="file" (change)="detectFiles2($event)"
                                       #input accept="application/pdf,image/*" class="m-img-upload-btn" style="padding: 5px; background-color: purple; color: white; width: 100%; border: none; border-radius: 0;" />

                                <div class="card1 images-container">
                                    <div class="row">
                                        <div *ngFor="let image of WitnessSignature; let i=index;" class="m-image-wrapper col-lg-3">
                                            <i class="fa fa-times m-delete-img" (click)="removeImage2(i)"></i>
                                            <img [attr.src]="image" *ngIf="image" style="cursor:pointer;" class="img-fluid1">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <p><b>Vehicle Photo<span style="color:red">*</span></b></p>
                                <input type="file" (change)="detectFiles3($event)"
                                       #input accept="application/pdf,image/*" class="m-img-upload-btn" style="padding: 5px; background-color: purple; color: white; width: 100%; border: none; border-radius: 0;" />

                                <div class="card1 images-container">
                                    <div class="row">
                                        <div *ngFor="let image of VehiclePhoto; let i=index;" class="m-image-wrapper col-lg-3">
                                            <i class="fa fa-times m-delete-img" (click)="removeImage3(i)"></i>
                                            <img [attr.src]="image" *ngIf="image" style="cursor:pointer;" class="img-fluid1">
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <!--<div class="col-lg-4">
                                <div class="form-group">
                                    <label for="BuyerSignature">Buyer Signature</label>
                                    <input type="file" formControlName="BuyerSignature" required>
                                </div>
                            </div>-->
                            <!--<div class="col-lg-4">
                                <div class="form-group">
                                    <label for="WitnessSignature">Witness Signature</label>
                                    <input type="file" formControlName="WitnessSignature" required>
                                </div>
                            </div>-->
                            <!--<div class="col-lg-4">
                                <div class="form-group">
                                    <label for="VehiclePhoto">Vehicle Photo</label>
                                    <input type="file" formControlName="VehiclePhoto" required>
                                </div>
                            </div>-->
                            <div class="form-group">
                                <button type="button" class="btn btn-secondary" (click)="goToPrevious()">Previous</button>
                                <button (click)="page()">
                                    <b>Preview </b>
                                </button>

                            </div>
                        </div>
                    </form>
                </fieldset>
            </div>
        </div>
    </div>
</div>

